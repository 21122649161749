import React, { useEffect, useCallback, useState } from "react";
import { withRouter, NavLink, useHistory, useLocation } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EventService from "../services/event.service";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import PublicIcon from '@material-ui/icons/Public';
import DeleteIcon from '@material-ui/icons/Delete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Grid, TextField, Button, FormControlLabel, Checkbox, Autocomplete, Paper, IconButton, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { de } from "date-fns/locale";
import { isValid, isAfter } from 'date-fns';
import Can from "../auth/Can";
import ReactQuill from 'react-quill';
import validator from 'validator'
import 'react-quill/dist/quill.snow.css';
import '../css/quill-custom.css';
import { createFilterOptions } from '@mui/material/Autocomplete';
import GoogleMapReact from 'google-map-react';

const filter = createFilterOptions();

const MapWithMarker = ({ center, markerCoords }) => {

  return (
    <div style={{ width: '100%', height: '200px', marginTop: 6, marginBottom: 6 }}>
      <GoogleMapReact
        defaultCenter={center}
        defaultZoom={15}
        center={markerCoords || center} // Set the center to markerCoords if available, otherwise use defaultCenter
      >
        {/* Marker for selected address */}
        {markerCoords && (
          <img
            src="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
            alt="Marker"
            style={{
              height: '30px',
              width: '30px',
              transform: 'translate(-50%, -50%)'
            }}
          />
        )}
      </GoogleMapReact>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  mainContainer: {
    marginLeft: "40px",
    marginRight: "40px",
    marginTop: "24px",
  },
  root: {
    flexGrow: 1,
  },
  button: {
    //marginRight: "1px !important"
  },
  paper: {
    padding: 10
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },

  progress: {
    color: "#000"
  },

  customErrorLabel: {
    color: "#d32f2f",
    fontSize: "0.75rem",
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "14px",
    marginBottom: 0,
    marginLeft: "14px"
  },

  editorContainer: {
    position: 'relative',
  },
  placeholder: {
    position: 'absolute',
    top: '50px',
    left: '15px',
    pointerEvents: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontWeight: 40,
    fontSize: '1rem',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    color: "rgba(0, 0, 0, 0.57)",
  },
  placeholderHidden: {
    display: 'none'
  },

  errorBorder: {
    border: '1px solid #d32f2f',
    borderRadius: '5px',
  },

}));

const iconCheckBox = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIconCheckBox = <CheckBoxIcon fontSize="small" />;

// Array of required field names
const requiredFields = ['title', 'description', 'start_date', 'end_date', 'venue', 'organizer', 'categories', 'tags', 'imageFile'];
const quillToolbar = {
  toolbar: [
    [{ 'header': [3, 4, 5, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    ['link'],
    ['clean']
  ],
};


const EventCreate = ({ auth0 }) => {
  const classes = useStyles();
  const [isWholeDayEvent, setIsWholeDayEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [venueOptions, setVenueOptions] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [selectedOrginizer, setSelectedOrginizer] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const [organizerOptions, setOrganizerOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [image, setImage] = useState(null);
  const [newEvent, setNewEvent] = useState({
    author: "1",
    status: "publish",
    show_map: true,
    show_map_link: true,
    slug: '',
    ticketed: false,
    title: '',
    description: '',
    start_date: null,
    end_date: null,
    timezone: "Europe/Berlin",
    timezone_abbr: "CEST",
    website: '',
    all_day: false,
    tags: []
  });

  const { user } = auth0;

  // State to hold form validation errors
  const [formErrors, setFormErrors] = useState({});
  const [editorFocused, setEditorFocused] = React.useState(true);
  const [open, toggleOpen] = React.useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    id: null,
    venue: '',
    address: '',
    city: '',
    country: '',
    province: '',
    zip: '',
    website: '',
    phone: ''
  });
  const [dialogErrors, setDialogErrors] = useState({
    venue: '',
    address: '',
    city: '',
    country: '',
    zip: '',
    website: '',
  });
  const [selectedPlaceCoords, setSelectedPlaceCoords] = useState(null);

  const GOOGLE_MAPS_API_KEY = 'AIzaSyAZYqhvRDkNZL8zSvBvF7DhXTipLSUZD_Q';

  function loadScript(src, position, id) {
    if (!position) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);

    script.onload = () => {
      console.log('Script loaded');
    };
  }

  const autocompleteService = { current: null }

  const [addressValue, setAddressValue] = React.useState(null);
  const [inputAddressValue, setInputAddressValue] = React.useState('');
  const [addressOptions, setAddressOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  if (typeof window !== 'undefined' && !loaded.current) {
    if (window.google && window.google.maps) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    } else {
      console.error('Google Maps API or Places API is not available');
    }

    loaded.current = true;
  }


  const fetchPlaces = (request, callback) => {
    if (autocompleteService.current) {
      // Add the language parameter to the request
      request.language = 'de'; // 'de' is the language code for German

      autocompleteService.current.getPlacePredictions(request, callback);
    } else {
      console.error('AutocompleteService is not available');
    }
  }


  const getToken = useCallback(async () => {
    const { getAccessTokenSilently } = auth0;
    const token = await getAccessTokenSilently();
    return token;
  }, [auth0]);

  const fetchVenueOptions = useCallback(async () => {
    try {
      const token = await getToken();
      const venuesData = await EventService.getVenues(token);
      if (Array.isArray(venuesData.data)) {
        setVenueOptions(prevOptions => [...prevOptions, ...venuesData.data]);
        //setVenueOptions(venuesData.data);
      } else {
        console.error('Venue options data is not in the correct format');
      }
    } catch (error) {
      console.error('Error fetching venue options', error);
    }
  }, [getToken]);

  const fetchOrganizerOptions = useCallback(async () => {
    try {
      const token = await getToken();
      const organizersData = await EventService.getOrganizers(token);
      if (Array.isArray(organizersData.data)) {
        setOrganizerOptions(organizersData.data);
      } else {
        console.error('Organizer options data is not in the correct format');
      }
    } catch (error) {
      console.error('Error fetching organizer options', error);
    }
  }, [getToken]);

  const fetchTagOptions = useCallback(async () => {
    try {
      const token = await getToken();
      const tagsData = await EventService.getTags(token);
      if (Array.isArray(tagsData.data)) {
        setTagOptions(tagsData.data);
      } else {
        console.error('Tag options data is not in the correct format');
      }
    } catch (error) {
      console.error('Error fetching tag options', error);
    }
  }, [getToken]);

  const fetchCategoriesOptions = useCallback(async () => {
    try {
      const token = await getToken();
      const categoriesData = await EventService.getCategories(token);
      if (Array.isArray(categoriesData.data)) {
        setCategoriesOptions(categoriesData.data);
      } else {
        console.error('Categories options data is not in the correct format');
      }
    } catch (error) {
      console.error('Error fetching categories options', error);
    }
  }, [getToken]);

  const location = useLocation();
  const { selectedValues } = location.state || {};

  useEffect(() => {
    let active = true;

    if (location.state && location.state.selectedValues) {
      const selectedValues = location.state.selectedValues;
      // Use selectedValues to pre-fill the fields
      setNewEvent({
        ...newEvent,
        title: selectedValues.title || '',
        image: selectedValues.image || '',
        description: selectedValues.description || '',
        venue: selectedValues.venue ? { id: selectedValues.venue.id } : null,
        organizer: selectedValues.organizer ? { id: selectedValues.organizer.id } : null,
        categories: selectedValues.category ? { id: selectedValues.category.id } : null,
        tags: selectedValues.tag_ids || [],
        website: selectedValues.url || '',
      });
      setSelectedVenue(selectedValues.venue);
      setSelectedOrginizer(selectedValues.organizer);
      setSelectedCategories(selectedValues.category);
      setSelectedTags(selectedValues.tag_ids);
      setImage(selectedValues.image);
    }


    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputAddressValue === '') {
      setAddressOptions(addressValue ? [addressValue] : []);
      return undefined;
    }

    fetchPlaces({ input: inputAddressValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (addressValue) {
          newOptions = [addressValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setAddressOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [addressValue, inputAddressValue, selectedValues]);

  useEffect(() => {
    fetchVenueOptions();
    fetchOrganizerOptions();
    fetchTagOptions();
    fetchCategoriesOptions();
  }, [auth0, fetchCategoriesOptions, fetchOrganizerOptions, fetchTagOptions, fetchVenueOptions]);


  const isStartDateGreaterThanEndDate = (startDate, endDate) => {
    const parsedStartDate = typeof startDate === 'string' ? new Date(startDate) : startDate;
    const parsedEndDate = typeof endDate === 'string' ? new Date(endDate) : endDate;

    return isAfter(parsedStartDate, parsedEndDate);
  };

  const isValidDate = (date) => {
    const parsedDate = typeof date === 'string' ? new Date(date) : date;
    return isValid(parsedDate)
  };

  const validateDialogFields = () => {
    let isValid = true;
    let errors = {};

    if (!dialogValue.venue) {
      isValid = false;
      errors.venue = 'Wo findet das Event statt? Bitte gib den Namen des Veranstaltungsortes an.';
    }

    if (!addressValue) {
      isValid = false;
      errors.address = 'Wo genau befindet sich die Location? Bitte gib die Adresse an.';
    }

    if (!dialogValue.address || dialogValue.address === " ") {
      isValid = false;
      errors.address = 'Bitte eine vollständige Adresse eingeben.';
    }

    /*if (!dialogValue.address) {
      isValid = false;
      errors.address = 'Wo genau befindet sich die Location? Bitte gib die Adresse an';
    }*/
    /*if (!dialogValue.city) {
      isValid = false;
      errors.city = 'In welcher Stadt befindet sich die Location?';
    }*/
    /*if (!dialogValue.country) {
      isValid = false;
      errors.country = 'Land is required';
    }*/
    /*if (!dialogValue.zip) {
      isValid = false;
      errors.zip = 'Bitte gib die Postleitzahl ein';
    }*/

    if (!isValidUrl(dialogValue.website)) {
      isValid = false;
      errors.website = 'Der eingegebene Link ist ungültig';
    }


    setDialogErrors(errors);
    return isValid;
  }


  const handleRemoteTagCreation = async (newTagName) => {
    try {

      const token = await getToken();
      const response = await EventService.createTags(newTagName, token);
      const newTag = response.data;

      setTagOptions((prevOptions) => [...prevOptions, newTag]);

      return newTag
    } catch (error) {
      console.error('Error creating tag:', error);
      throw error;
    }
  }


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setNewEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear the error for the corresponding field when it's filled
    /*setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: null,
    }));*/

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: null,
      start_date: (name === 'start_date' || name === 'end_date') ? null : prevErrors.start_date,
      end_date: (name === 'start_date' || name === 'end_date') ? null : prevErrors.end_date,
    }));
  };

  const handleInputChangeDetails = (value) => {

    setNewEvent((prevState) => ({
      ...prevState,
      description: value,
    }));

    // Clear the error for the corresponding field when it's filled
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      description: null,
    }));

  };

  const history = useHistory();

  const handleWholeDayEventChange = (e) => {
    setIsWholeDayEvent(e.target.checked);
    setNewEvent((prevState) => ({
      ...prevState,
      all_day: e.target.checked
      // or venue_id: newValue?.id, depends on your venueOptions structure
    }));
  };

  /*const handleBlur = (e) => {
    const { name, value } = e.target;
    if (requiredFields.includes(name) && !value.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        title: name === 'title' ? 'Was ist der Titel für dein Event?' : null,
        description: name === 'description' ? 'Beschreibe dein Event, damit interessierte Besucher sehen, was sie erwartet. ' : null,
      }));
    }
  };*/

  const handleEditorFocus = () => {
    setEditorFocused(false);
  };

  const handleEditorBlur = () => {
    setEditorFocused(true);
  };

  const isValidUrl = (value) => {

    if (!value) {
      return true;
    }
    if (validator.isURL(value)) {
      return true;
    }

    return false;
  }

  const handleAddVenueDialogClose = () => {
    setDialogValue({
      venue: '',
      address: '',
      city: '',
      country: '',
      zip: '',
      website: '',
      province: '',
      phone: ''
    });
    setDialogErrors({});
    setNewEvent((prevState) => ({
      ...prevState,
      venue: null
    }));

    setAddressValue(null);

    setSelectedPlaceCoords(null);

    setSubmissionSuccess(false);
    toggleOpen(false);
  };

  const processURL = (url) => {

    if (!url) {
      return '';
    }

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url; // Add "https://" if not present
    }
    return url;
  }

  const fetchVenueOptionsAndSetSelected = async () => {
    try {
      const token = await getToken();
      const venuesData = await EventService.getVenues(token);
  
      if (Array.isArray(venuesData.data)) {
        setVenueOptions(venuesData.data);
      } else {
        console.error('Venue options data is not in the correct format');
      }
    } catch (error) {
      console.error('Error fetching venue options', error);
    }
  };
  
  const fetchTagOptionsAndSetSelected = async () => {
    try {
      const token = await getToken();
      const tagsData = await EventService.getTags(token);

      if (Array.isArray(tagsData.data)) {
        setTagOptions(tagsData.data);
      } else {
        console.error('Tags options data is not in the correct format');
      }
    } catch (error) {
      console.error('Error fetching tag options', error);
    }
  };


  const handleAddVenueDialogSubmit = async (event) => {
    try {
      setLoading(true);
      const token = await getToken();
      event.preventDefault();
  
      if (!validateDialogFields()) {
        return;
      }
  
      dialogValue.website = processURL(dialogValue.website);
  
      await EventService.createVenue(dialogValue, token);
  
      await fetchVenueOptionsAndSetSelected();
  
      //const selectedVenueOption = { id: venueDialogData.data.id };
      //setSelectedVenue(selectedVenueOption);
  
      setSubmissionSuccess(true);
      // handleAddVenueDialogClose();
    } catch (error) {
      console.error('Error in handleAddVenueDialogSubmit: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {

    const hasErrors = requiredFields.some((fieldName) => {
      if (fieldName === 'start_date' || fieldName === 'end_date') {
        return !newEvent[fieldName];
      }
      if (fieldName === 'venue' || fieldName === 'organizer' || fieldName === 'categories') {
        return !newEvent[fieldName]?.id;
      }
      if (fieldName === 'tags') {
        //return newEvent[fieldName].length === 0;
        return !newEvent[fieldName] || !Array.isArray(newEvent[fieldName]) || newEvent[fieldName].length === 0;
      }
      return typeof newEvent[fieldName] === 'string' && !newEvent[fieldName].trim();
    });


    if (hasErrors || (newEvent.start_date && newEvent.end_date && isStartDateGreaterThanEndDate(newEvent.start_date, newEvent.end_date)) || !isValidDate(newEvent.start_date) || !isValidDate(newEvent.end_date) || !image || isContentEmpty(newEvent.description) || !isValidUrl(newEvent.website)) {
      const newErrors = {};
      requiredFields.forEach((fieldName) => {
        if (fieldName === 'start_date' && !newEvent[fieldName]) {
          newErrors[fieldName] = 'Wann geht das Event los?';
        } else if (fieldName === 'start_date' && !isValidDate(newEvent.start_date)) {
          newErrors[fieldName] = 'Bitte ein gültiges Datum eintragen';
        } else if (fieldName === 'end_date' && !newEvent[fieldName]) {
          newErrors[fieldName] = 'Wann ist das Event ungefähr zu Ende?';
        } else if (fieldName === 'end_date' && !isValidDate(newEvent.end_date)) {
          newErrors[fieldName] = 'Bitte ein gültiges Datum eintragen';
        } else if (fieldName === 'title' && !newEvent[fieldName]) {
          newErrors[fieldName] = 'Was ist der Titel für dein Event?';
        } else if (fieldName === 'description' && (!newEvent[fieldName] || newEvent[fieldName] === '<p><br></p>')) {
          newErrors[fieldName] = 'Beschreibe dein Event, damit interessierte Besucher sehen, was sie erwartet';
        } else if (fieldName === 'venue' && !newEvent[fieldName]?.id) {
          newErrors[fieldName] = 'Wo findet das Event statt?';
        } else if (fieldName === 'organizer' && !newEvent[fieldName]?.id) {
          newErrors[fieldName] = 'Wer veranstaltet das Event?';
        } else if (fieldName === 'categories' && !newEvent[fieldName]?.id) {
          newErrors[fieldName] = 'Bitte wähle den Event Typ, der am besten passt.';
        } else if (fieldName === 'tags' && (!newEvent[fieldName] || newEvent[fieldName].length === 0)) {
          newErrors[fieldName] = 'Wähle ein paar Schlagworte, die dein Event zusätzlich beschreiben';
        } else if (fieldName === 'imageFile' && !newEvent[fieldName] && !image) {
          newErrors[fieldName] = 'Bitte ein Bild zum Event einfügen';
        }
      });

      // Perform additional error checks for start_date and end_date
      if (newEvent.start_date && newEvent.end_date && isStartDateGreaterThanEndDate(newEvent.start_date, newEvent.end_date)) {
        newErrors.start_date = 'Der Start muss vor dem Ende sein';
        newErrors.end_date = 'Das Ende muss nach dem Start sein';
      }

      if (!isValidUrl(newEvent.website)) {
        newErrors.website = 'Der eingegebene Link ist ungültig';
      }


      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return;
    }


    newEvent.slug = newEvent.title;
    newEvent.website = processURL(newEvent.website);

    try {

      setLoading(true); // Set the loading state to true

      const token = await getToken()
      let formData = new FormData();
      formData.append("file", image);

      if (image instanceof File) {

        const imageUrl = await EventService.uploadEventImage(formData, token);
        newEvent.image = imageUrl.data.imageUrl;
      } 

      /*setNewEvent((prevState) => ({
        ...prevState,
        image: imageUrl.data.imageUrl,
      }));*/

      //newEvent.start_date = newEvent.start_date.toISOString();
      //newEvent.end_date = newEvent.end_date.toISOString();


      await EventService.createEvent(newEvent, token);
      setLoading(false); // Set the loading state to false after saving
      history.push("/events");
    } catch (error) {
      console.error("Error creating event", error);
      setLoading(false); // Set the loading state to false after saving
    }
  };


  const handleImageDelete = () => {
    setImage(null);
  };

  const isContentEmpty = (content) => {
    const strippedContent = content.replace(/<[^>]*>/g, ''); // Remove HTML tags
    return strippedContent.trim() === ''; // Check if the stripped content is empty
  };


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
  
    // Check if the file size is greater than 3 MB
    if (file && file.size > 3 * 1024 * 1024) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        imageFile: 'Bildgröße darf nicht größer als 3 MB sein. Bitte wähle ein kleineres Bild',
      }));
    } else {
      setImage(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        imageFile: null, // Clear the error for the image upload when it's filled
      }));
    }
  };


  return (
    <Can
      user={user}
      perform="EventManager"
      no={() => {
        history.push("/forbidden")
        return null
      }}
      yes={() => (
        <div className={classes.mainContainer}>

          {loading && (
            <div className={classes.overlay}>
              <CircularProgress className={classes.progress} />
            </div>
          )}

          <Grid container className={classes.root} spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                className={classes.backButton}
                startIcon={<ArrowBackIosSharpIcon />}
                component={NavLink}
                to="/events"
              >
                Zurück
              </Button>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end" >
              <Button
                variant="contained"
                color="success"
                className={classes.button}
                onClick={handleSave}
                endIcon={<PublicIcon />}
              >
                Event Veröffentlichen
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Titel"
                value={newEvent.title}
                fullWidth
                required
                //InputLabelProps={{ shrink: true }}
                variant="outlined"
                name="title"
                onChange={handleInputChange}
                //onBlur={handleBlur}
                error={Boolean(formErrors.title)}
                helperText={formErrors.title}
              />
            </Grid>
            <Grid item xs={12} >
              <input
                type="file"
                accept="image/*"
                id="image-upload-input"
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
               <label htmlFor="image-upload-input">
                <Button variant="contained" component="span" endIcon={<AddPhotoAlternateOutlinedIcon />}>
                  Bild zum Event einfügen
                </Button>
                <br/>
                <Typography variant="caption">* Mit dem Upload des Bildes bestätigst du, dass du die Rechte hast, das Bild zu verwenden</Typography>
              </label>
              {formErrors.imageFile && (
                <p className={classes.customErrorLabel}>{formErrors.imageFile}</p>
              )}
            </Grid>

            {image && (
              <Grid item xs={6} xl={3} >
                <Paper className={classes.paper} elevation={3}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="h6">Eventbild</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton className={classes.deleteButton} onClick={handleImageDelete} >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {/*<img src={URL.createObjectURL(image)} alt="Eventbild" style={{ width: "100%", marginTop: "10px" }} />*/}
                  <img src={image instanceof File ? URL.createObjectURL(image) : image} alt="Eventbild" style={{ width: "100%", marginTop: "10px" }} />
                </Paper>
              </Grid>
            )}
            <Grid item xs={12}>
              {/*<TextField
              label="Beschreibe dein Event"
              value={newEvent.description}
              fullWidth
              required
              //InputLabelProps={{ shrink: true }}
              variant="outlined"
              multiline
              rows={4}
              name="description"
              onChange={handleInputChange}
              //onBlur={handleBlur}
              error={Boolean(formErrors.description)}
              helperText={formErrors.description}
          /> */}
              <div className={classes.editorContainer}>
                <label htmlFor="editor" className={(isContentEmpty(newEvent.description) && editorFocused) ? classes.placeholder : classes.placeholderHidden}>
                  Beschreibe dein Event &#42;
                </label>
                <ReactQuill id="editor" modules={quillToolbar} className={Boolean(formErrors.description) ? classes.errorBorder : ''} theme="snow" value={newEvent.description} onChange={handleInputChangeDetails} onFocus={handleEditorFocus} onBlur={handleEditorBlur} />
                {formErrors.description && (
                  <p className={classes.customErrorLabel}>{formErrors.description}</p>
                )}
              </div>

            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                {isWholeDayEvent ? (
                  <DatePicker
                    label="Event Start"
                    disablePast
                    value={newEvent.start_date}
                    onChange={(date) =>
                      handleInputChange({
                        target: { name: "start_date", value: date },
                      })
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} required helperText={formErrors.start_date} error={Boolean(formErrors.start_date)} label="Event Start" variant="outlined" fullWidth />
                    )}
                  />
                ) : (
                  <DateTimePicker
                    label="Event Start"
                    disablePast
                    value={newEvent.start_date}
                    timezone="UTC"
                    onChange={(date) =>
                      handleInputChange({
                        target: { name: "start_date", value: date },
                      })
                    }
                    fullWidth
                    ampm={false}
                    renderInput={(params) => (
                      <TextField {...params} required helperText={formErrors.start_date} error={Boolean(formErrors.start_date)} label="Event Start" variant="outlined" fullWidth />
                    )}
                  />
                )}
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                {isWholeDayEvent ? (
                  <DatePicker
                    label="Event Ende"
                    disablePast
                    value={newEvent.end_date}
                    onChange={(date) =>
                      handleInputChange({
                        target: { name: "end_date", value: date },
                      })
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} required helperText={formErrors.end_date} error={Boolean(formErrors.end_date)} label="Event Ende" variant="outlined" fullWidth />
                    )}
                  />
                ) : (
                  <DateTimePicker
                    label="Event Ende"
                    disablePast
                    value={newEvent.end_date}
                    timezone="UTC"
                    onChange={(date) =>
                      handleInputChange({
                        target: { name: "end_date", value: date },
                      })
                    }
                    fullWidth
                    ampm={false}
                    renderInput={(params) => (
                      <TextField {...params} required helperText={formErrors.end_date} error={Boolean(formErrors.end_date)} label="Event Ende" variant="outlined" fullWidth />
                    )}
                  />
                )}
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isWholeDayEvent}
                    onChange={handleWholeDayEventChange}
                  />
                }
                label="Ganztägiges Event"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="venue-autocomplete"
                options={venueOptions}
                getOptionLabel={(option) => {
                  if (!option || typeof option !== 'object') {
                    return ''; 
                  }
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.venue || ''; 
                }}
                renderOption={(props, option) => <li {...props}>{option.venue}</li>}
                //isOptionEqualToValue={(option, value) => option?.venue === value?.venue}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                value={selectedVenue}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setTimeout(() => {
                      toggleOpen(true);
                      setDialogValue({
                        venue: newValue
                      });
                       setSelectedVenue(null);
                    });
                  } else if (newValue && newValue.inputValue) {
                    toggleOpen(true);
                    setDialogValue({
                      venue: newValue.inputValue
                    });
                    setSelectedVenue(null);
                  } else {
                    //setValue(newValue);
                    handleInputChange({
                      target: {
                        name: "venue",
                        value: newValue ? { id: newValue.id } : null,
                      },
                    });
                    setSelectedVenue(newValue); 

                  }

                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some((option) => inputValue.toLowerCase() === option.venue.toLowerCase());

                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      venue: `Location neu hinzufügen "${params.inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField {...params} required helperText={formErrors.venue} error={Boolean(formErrors.venue)} label="Veranstaltungsort" variant="outlined" fullWidth />
                )}
              />
               <div style={{ marginTop: 5, marginLeft: 14 }}>
                <Typography variant="subtitle1">Veranstaltungsort nicht dabei?</Typography>
                <Button variant="outlined" size="small" color="info" style={{ marginTop: 5 }} endIcon={<AddLocationAltOutlinedIcon />} onClick={(date) => {
                   toggleOpen(true);
                   
                }
                } >Erstellen</Button>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="organizer-autocomplete"
                options={organizerOptions}
                value={organizerOptions.find(option => option.id === selectedOrginizer?.id) || null}
                getOptionLabel={(option) => option?.organizer || ""}
                isOptionEqualToValue={(option, value) => option?.organizer === value?.organizer}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: {
                      name: "organizer",
                      value: newValue ? { id: newValue.id } : null,
                    },
                  });
                  setSelectedOrginizer(newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} required helperText={formErrors.organizer} error={Boolean(formErrors.organizer)} label="Veranstalter" variant="outlined" fullWidth />
                )}
              />
            </Grid>


            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="categories-autocomplete"
                options={categoriesOptions}
                value={categoriesOptions.find(option => option.id === selectedCategories?.id) || null}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: {
                      name: "categories",
                      value: newValue ? { id: newValue.id } : null,
                    },
                  });
                  setSelectedCategories(newValue ? { id: newValue.id } : null)
                }}
                renderInput={(params) => (
                  <TextField {...params} required helperText={formErrors.categories} error={Boolean(formErrors.categories)} label="Event Typ / Kategorie" variant="outlined" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
            <Autocomplete
                multiple
                disableCloseOnSelect
                id="tag-autocomplete"
                options={tagOptions?.sort((a, b) => a.name.localeCompare(b.name))}
                value={(selectedTags || []).map((tagId) =>
                  tagOptions?.find((tag) => tag.id === tagId)
                )}
                getOptionLabel={(option) => option?.name || ""}
                renderOption={(props, option, { inputValue, selected }) => {
                  const matches = match(option.name, inputValue);
                  const parts = parse(option.name, matches);

                  return (
                    <li {...props}>
                      <Checkbox
                        icon={iconCheckBox}
                        checkedIcon={checkedIconCheckBox}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                loading={loading}
                onChange={async (event, newValue) => {
                  const newTags = newValue.filter((item) => 'inputValue' in item);

                  if (newTags.length > 0) {
                    setLoading(true);
                    const createdTags = [];
                  
                    try {
                      await Promise.all(
                        newTags.map(async (newTag, index) => {
                          try {
                            const createdTag = await handleRemoteTagCreation({ name: newTag.inputValue });
                            createdTags.push(createdTag);
                          } catch (error) {
                            console.error('Error creating tag:', error);
                            // Handle the error if needed
                          }
                        })
                      );
                  
                      // Add successfully created tags to the new value
                      newValue.pop();
                      newValue.push(...createdTags);
                    } catch (error) {
                      console.error('Error creating or fetching tags:', error);
                    } finally {
                      // Fetch tag options and set selected tags
                      await fetchTagOptionsAndSetSelected();
                  
                      // Update the state and selected tags
                      handleInputChange({
                        target: {
                          name: 'tags',
                          value: newValue ? newValue.map((item) => item.id) : [],
                        },
                      });
                      setSelectedTags(newValue ? newValue.map((item) => item.id) : []);
                  
                      // Reset loading state
                      setLoading(false);
                    }
                  }
                  
                  else {
                    handleInputChange({
                      target: {
                        name: "tags",
                        value: newValue ? newValue.map((item) => item.id) : [],
                      },
                    });
                    setSelectedTags(newValue ? newValue.map((item) => item.id) : []);
                  }
                }}
                
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some((option) => inputValue === option.name);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `Neu hinzufügen "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    helperText={formErrors.tags}
                    error={Boolean(formErrors.tags)}
                    label="Welche Schlagworte beschreiben dein Event?"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Link zu weiteren Informationen (Optional)"
                value={newEvent.website}
                fullWidth
                //InputLabelProps={{ shrink: true }}
                variant="outlined"
                name="website"
                placeholder="https://example.com"
                onChange={handleInputChange}
                inputProps={{ inputMode: 'url', pattern: 'https://.*' }}
                //onBlur={handleBlur}
                error={Boolean(formErrors.website)}
                helperText={formErrors.website}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                variant="contained"
                color="success"
                className={classes.button}
                onClick={handleSave}
                endIcon={<PublicIcon />}
              >
                Event Veröffentlichen
              </Button>
            </Grid>

          </Grid>

          <Dialog open={open} onClose={handleAddVenueDialogClose}>
            {submissionSuccess ? (
              // Success screen
              <div>
                <DialogTitle>Erfolg!</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                   Der Veranstaltungsort wurde erfolgreich hinzugefügt. Du kannst ihn für deine Veranstaltungen verwenden.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={handleAddVenueDialogClose}>Close</Button>
                </DialogActions>
              </div>
            ) : (
              // Add venue form
              <div>
                <DialogTitle>Location neu hinzufügen</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Hey, haben wir einen Ort in unserer Liste übersehen? Bitte füge ihn gerne hinzu!
                  </DialogContentText>
                  <br />
                  <TextField
                    margin="dense"
                    id="name"
                    fullWidth
                    required
                    value={dialogValue.venue}
                    error={Boolean(dialogErrors.venue)}
                    helperText={dialogErrors.venue}
                    onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        venue: event.target.value,
                      })
                    }
                    label="Name Veranstaltungsort"
                    type="text"
                  />
                  <Autocomplete
                    id="google-map-demo"
                    style={{ marginTop: 6 }}
                    fullWidth
                    margin="dense"
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.description
                    }
                    filterOptions={(x) => x}
                    options={addressOptions}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={addressValue}
                    noOptionsText="Suche eingeben..."
                    onChange={(event, newValue) => {
                      setAddressOptions(newValue ? [newValue, ...addressOptions] : addressOptions);
                      setAddressValue(newValue);

                      if (newValue) {
                        const placeId = newValue.place_id;
                        const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
                        placesService.getDetails({ placeId, language: 'de' }, (place, status) => {
                          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            const addressComponents = place.address_components;

                            let street = '';
                            let streetNumber = '';
                            let postalCode = '';
                            let country = '';
                            let city = '';
                            let province = '';

                            addressComponents.forEach(component => {
                              const type = component.types[0];
                              const longName = component.long_name;
                              const shortName = component.short_name;

                              if (type === 'route') {
                                street = longName;
                              } else if (type === 'street_number') {
                                streetNumber = shortName;
                              } else if (type === 'postal_code') {
                                postalCode = shortName;
                              } else if (type === 'country') {
                                country = longName;
                              } else if (type === 'locality') {
                                city = longName;
                              } else if (type === 'administrative_area_level_1') {
                                province = longName;
                              }
                            });

                            const fullAddress = `${street} ${streetNumber}`;

                            setDialogValue({
                              ...dialogValue,
                              address: fullAddress,
                              zip: postalCode,
                              country: country,
                              city: city,
                              province: province
                            });

                            // Set selected place coordinates
                            setSelectedPlaceCoords({
                              lat: place.geometry.location.lat(),
                              lng: place.geometry.location.lng()
                            });

                          } else {
                            console.error('Error fetching place details:', status);
                          }
                        });


                      }
                    }}

                    onInputChange={(event, newInputValue) => {
                      setInputAddressValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField required {...params} helperText={dialogErrors.address} error={Boolean(dialogErrors.address)} label="Adresse" fullWidth />
                    )}
                    renderOption={(props, option) => {
                      const matches =
                        option.structured_formatting.main_text_matched_substrings || [];

                      const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                      );

                      return (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                              <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                              {parts.map((part, index) => (
                                <Box
                                  key={index}
                                  component="span"
                                  sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                >
                                  {part.text}
                                </Box>
                              ))}
                              <Typography variant="body2" color="text.secondary">
                                {option.structured_formatting.secondary_text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                  />
                  <MapWithMarker
                    center={{ lat: 49.3987524, lng: 8.6724335 }} // Set the initial center of the map
                    markerCoords={selectedPlaceCoords} // Pass the coordinates of the selected place
                  />
                  <TextField
                    margin="dense"
                    label="Webseite"
                    type="text"
                    fullWidth
                    value={dialogValue.website}
                    placeholder="https://example.com"
                    error={Boolean(dialogErrors.website)}
                    helperText={dialogErrors.website}
                    name="website"
                    onChange={(event) =>

                      setDialogValue({
                        ...dialogValue,
                        website: event.target.value,
                      })

                    }
                  />
                  <TextField
                    margin="dense"
                    label="Telefon"
                    type="text"
                    fullWidth
                    value={dialogValue.phone}
                    placeholder="+49 000 0000000"
                    /*error={Boolean(dialogErrors.phone)}
                    helperText={dialogErrors.phone}*/
                    name="telefon"
                    onChange={(event) =>

                      setDialogValue({
                        ...dialogValue,
                        phone: event.target.value,
                      })

                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={handleAddVenueDialogClose}>Abbrechen</Button>
                  <Button variant="contained" type="submit" onClick={handleAddVenueDialogSubmit}>Hinzufügen</Button>
                </DialogActions>
              </div>
            )}

          </Dialog>
        </div>
      )}
    />

  );
};

export default withAuth0(withRouter(EventCreate));