import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { format } from 'date-fns';
import { de } from 'date-fns/locale';


const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    marginBottom: "15px",
    height: "100%"
  }
});


export default function EventDetailCard(props) {
  const classes = useStyles();

  return (

    <Card className={classes.root}>
      <CardContent>
        {props.details ?
          <div style={{ paddingLeft: 20 }}>
            <Typography gutterBottom variant="h5" component="h5">
              Anfrage: {props.details.eventType}
            </Typography>
            <br></br>
            <Typography variant="body1" color="textSecondary" component="p">
              <b>Datum:</b> {format(new Date(props.details.date), "dd.MM.yyyy", { locale: de }).toString()} {props.details.time}
            </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
            <b>Alternatives Datum:</b> {format(new Date(props.details.altDate), "dd.MM.yyyy", { locale: de }).toString()} {props.details.time}
            </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
            <b>Personen:</b> {props.details.nrOfPeople}
            </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
            <b>Price:</b> <i>(min)</i> {props.details.price}€ - <i>(max)</i> {props.details.priceMax}€
            </Typography>
            <br></br>
            <Typography variant="body1" color="textSecondary" component="p">
            <b>Details:</b> {props.details.details}
            </Typography>
            <br></br>
            <Typography variant="body1" color="textSecondary" component="p">
            <b>Name:</b> {props.details.firstname} {props.details.lastname}
            </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
            <b>Telefon:</b> {props.details.phone}
            </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
            <b>E-Mail:</b> {props.details.email}
            </Typography>
          
          </div>
          :   
          <Typography variant="h5" color="textSecondary" component="h5" style={{ textAlign: "center", marginTop: "25vh" }}>
            Bitte wählen eine Anfrage aus der Liste aus
          </Typography>}
      </CardContent>
    </Card>
  );
}
