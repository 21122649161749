import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { NavBar, Footer, Loading } from "./components";
import { Tip, Home, Lunch, Profile, NextWeek, CurrentWeek, LongerValid, Support, Events, EventCreate, EventDetails, Eventlocation, EventlocationProfile, EventlocationRequests, Forbidden } from "./views";
import CssBaseline from '@material-ui/core/CssBaseline';
import { withAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from "./auth/protected-route";

import "./App.css";

class App extends React.Component {
  render() {
    const { isLoading } = this.props.auth0;

    if (isLoading) {
      return <Loading />;
    }

    return (

      <div id="app" className="d-flex flex-column h-100">

        <CssBaseline />
        <NavBar />
        <div className="container flex-grow-1">
          <div className="mt-5">
            <Switch>
              <ProtectedRoute path="/" exact component={Home} />
              <ProtectedRoute path="/lunch"  exact component={Lunch} />
              <ProtectedRoute path="/forbidden"  exact component={Forbidden} />
              <ProtectedRoute path="/profile" exact component={Profile} />
              <ProtectedRoute path="/tip" exact component={Tip} />
              <ProtectedRoute path="/support" exact component={Support} />
              <ProtectedRoute path="/currentweek" exact component={CurrentWeek} />
              <ProtectedRoute path="/nextweek" exact component={NextWeek} />
              <ProtectedRoute path="/longervalid" exact component={LongerValid} />
              <ProtectedRoute path="/events" exact component={Events} />
              <ProtectedRoute path="/events/create" exact component={EventCreate} />
              <ProtectedRoute path="/events/:id" component={EventDetails} />
              <ProtectedRoute path="/eventlocation" exact component={Eventlocation} />
              <ProtectedRoute path="/eventlocation/profile" exact component={EventlocationProfile} />
              <ProtectedRoute path="/eventlocation/requests" exact component={EventlocationRequests} />
              <Redirect from="//" to="/" />

            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withAuth0(App);
