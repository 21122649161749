import React, { Fragment } from "react";

import LunchContent from "../components/lunch-content";

class Lunch extends React.Component {
  render() {
    return (
      <Fragment>
        <LunchContent />
      </Fragment>
    );
  }
}

export default Lunch;
