import http from "../http-common";

class UploadFilesService {

    upload(token, file, onUploadProgress) {
        let formData = new FormData();
        formData.append("file", file);
        return http.post("/restaurant/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            },
            onUploadProgress,
        });
    }
    
    getFiles() {
        return http.get("/files");
    }
}

export default new UploadFilesService();