import React, { Fragment } from "react";

import Create from "../components/event-create.component";
import { withStyles } from '@material-ui/core/styles';

import { withAuth0 } from "@auth0/auth0-react";

const useStyles = theme => ({
  profileContainer: {
    marginLeft: theme.spacing(3)
  }
});

class EventCreate extends React.Component {
  render() {

    return (
          <Fragment>
            <Create />
          </Fragment>
  
    );
  }
}

export default withStyles(useStyles)(withAuth0(EventCreate));
