import http from "../http-common";

class EventService {

  getEvents = async (token) => {
    return http.get("/event", { headers: { "Authorization": `Bearer ${token}` } });
    //return http.get("/event/organizer/events", { headers: { "Authorization": `Bearer ${token}` } });
  }

  getEventDetails = async (id, token) => {
    return http.get(`/event/${id}`, { headers: { "Authorization": `Bearer ${token}` } });
    //return http.get("/event/organizer/events", { headers: { "Authorization": `Bearer ${token}` } });
  }

  getVenues = async (token) => {
    return http.get("/event/venue", { headers: { "Authorization": `Bearer ${token}` } });
  }

  createVenue = async (data, token) => {
    return http.post("/event/venue", data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  getOrganizers = async (token) => {
    return http.get("/event/organizer", { headers: { "Authorization": `Bearer ${token}` } });
  }

  getTags = async (token) => {
    return http.get("/event/tags", { headers: { "Authorization": `Bearer ${token}` } });
  }

  createTags = async (data, token) => {
    return http.post("/event/tags", data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  getCategories = async (token) => {
    return http.get("/event/categories", { headers: { "Authorization": `Bearer ${token}` } });
  }

  createEvent = async (data, token) => {
    return http.post("/event", data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  updateEvent = async (id, data, token) => {
    return http.post(`/event/${id}`, data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  deleteEvent = async (id, token) => {
    return http.delete(`/event/${id}`, { headers: { "Authorization": `Bearer ${token}` } });
  }

  uploadEventImage = async (file, token) => {
    return http.post("/event/upload", file, { headers: { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${token}` } });
  }



}

export default new EventService();