import http from "../http-common";

class EventLocationDataService {
  
  getEventLocation = async (token) => {
    return http.get("/eventlocation/profile", { headers: { "Authorization": `Bearer ${token}` } });
  }

  getEventLocationImages = async (token) => {
    return http.get("/eventlocation/img", { headers: { "Authorization": `Bearer ${token}` } });
  }

  createEventLocation = async (data, token) => {
    return http.post(`/eventlocation/profile`, data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  updateEventLocation = async (data, token) => {
    return http.put(`/eventlocation/profile`, data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  getEvenLocationRequests = async (token) => {
    return http.get("/eventlocation/requests", { headers: { "Authorization": `Bearer ${token}` } });
  }

  getEvenLocationRequestsCount = async (token) => {
    return http.get("/eventlocation/requests/count", { headers: { "Authorization": `Bearer ${token}` } });
  }
  
}

export default new EventLocationDataService();