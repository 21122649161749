
import React from "react";
import logo from '../logo.png'; 
class MamfitoLogo extends React.Component {

    render() {
        return (
            <div className="text-center hero">
                <a href="/"><img
                    className="mb-3 app-logo mf-logo"
                    src={logo}
                    alt="Mamfito logo"
                    width="160"
                /></a>
            </div>
        );
    }
}
export default MamfitoLogo;