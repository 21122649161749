import { React } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuth0 } from "@auth0/auth0-react";
import Can from "../auth/Can";
import LunchIcon from '../img/lunch.png';
import EventlocationIcon from '../img/eventlocation.png';
import EventsIcon from '../img/events.png';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(7)
  },
  paper: {
    height: 200,
    width: 200,
    color: '#00A0B0',
    '&:hover': { boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)' },
  },
  paperDisabled: {
    height: 200,
    width: 200,
    color: '#939597'
  },
  paperLink: {
    color: '#00A0B0',
    textDecoration: "none"
  },
  control: {
    padding: theme.spacing(2),
  },
  controlDate: {
    padding: theme.spacing(2),
    color: '#939597'
  },
  controlDisabled: {
    padding: theme.spacing(2),
    color: '#939597'
  },
  tilesAlignment: {
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(2),
  },
  labelAlignment: {
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(7),
  },
}));




const HomeContent = () => {

  const classes = useStyles();
  const { user } = useAuth0();
  const { isAuthenticated } = useAuth0();
  const { loginWithRedirect } = useAuth0();


  if (!isAuthenticated) {
    loginWithRedirect()
  }


  return (

    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <Grid container justifyContent="center" alignContent="center" spacing={3}>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Willkommen in deinem Mamfito Cockpit
            </Typography>
            <Typography variant="h6" align="center">
              {user.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
        <h3 className={classes.labelAlignment} >Verwalte deine Angebote</h3>
        <Grid container justifyContent="flex-start" alignContent="flex-start" className={classes.tilesAlignment} spacing={3}>

          <Can
            user={user}
            perform="NoLunch"
            yes={() => (
              <Grid key={0} item>
                <Paper className={classes.paperDisabled} square>
                  <Grid container wrap="wrap" spacing={2}  >
                    <Grid item>
                      <Typography className={classes.control} ><b>Mittagsmenü verwalten</b></Typography>
                      <br />
                      <img style={{ margin: "auto", display: "block" }} height={80} src={LunchIcon} alt="Mittagsmenü" />
                      <Typography className={classes.controlDate}></Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

            )}
            no={() => (
              <Grid key={0} item>
                <Paper className={classes.paper} elevation={3} square>
                  <Grid container wrap="wrap" spacing={2} component={NavLink} className={classes.paperLink} to="/lunch">
                    <Grid item>
                      <Typography className={classes.control} ><b>Mittagsmenü verwalten</b></Typography>
                      <br />
                      <img style={{ margin: "auto", display: "block" }} height={80} src={LunchIcon} alt="Mittagsmenü" />
                      <Typography className={classes.controlDate}></Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

            )}
          />

          <Grid key={1} item>
            <Paper className={classes.paper} elevation={3} square>
              <Grid container wrap="wrap" spacing={2} component={NavLink} className={classes.paperLink} to="/eventlocation" >
                <Grid item>
                  <Typography className={classes.control}><b>Deine Location verwalten</b></Typography>
                  <br />
                  <img style={{ margin: "auto", display: "block" }} height={80} src={EventlocationIcon} alt="Eventlocation" />
                  <Typography className={classes.controlDate}></Typography>
                </Grid>
              </Grid>

            </Paper>
          </Grid>

          <Can
            user={user}
            perform="EventManager"
            no={() => (
              <Grid key={2} item>
                <Paper className={classes.paperDisabled} square>
                  <Grid container wrap="wrap" spacing={2} >
                    <Grid item>
                      <Typography className={classes.control}><b>Deine Events verwalten</b></Typography>
                      <br />
                      <img style={{ margin: "auto", display: "block" }} height={80} src={EventsIcon} alt="Events" />
                      <Typography className={classes.controlDate}></Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            yes={() => (
              <Grid key={2} item>
                <Paper className={classes.paper} elevation={3} square>
                  <Grid container wrap="wrap" spacing={2} component={NavLink} className={classes.paperLink} to="/events" >
                    <Grid item>
                      <Typography className={classes.control}><b>Deine Events verwalten</b></Typography>
                      <br />
                      <img style={{ margin: "auto", display: "block" }} height={80} src={EventsIcon} alt="Events" />
                      <Typography className={classes.controlDate}></Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

            )}
          />
        </Grid>
      </Grid>

    </Grid>
  );
}


export default withAuth0(HomeContent)