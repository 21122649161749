import React, { Fragment } from "react";

import EventlocationRequestsContent from "../components/eventlocationrequests-content.component";
import { withStyles } from '@material-ui/core/styles';

import { withAuth0 } from "@auth0/auth0-react";

const useStyles = theme => ({
  profileContainer: {
    marginLeft: theme.spacing(3)
  }
});

class EventlocationRequests extends React.Component {
  render() {

    return (
          <Fragment>
            <EventlocationRequestsContent />
          </Fragment>
  
    );
  }
}

export default withStyles(useStyles)(withAuth0(EventlocationRequests));
