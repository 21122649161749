import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Loading } from "../components/index";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const { match } = props;
        const { id } = match.params;

        const AuthenticatedComponent = withAuthenticationRequired(Component, {
          onRedirecting: () => <Loading />,
        });

        return <AuthenticatedComponent id={id} {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
