import http from "../http-wp";

class WPDataService {

  getWP = async (id) =>  {
    return http.get(`/read.php?id=${id}`);
  }

  updateWP(data) {
    return http.post(`/update.php`, data);
  }

  updateBusinessHours(data) {
    return http.post(`/businesshours.php`, data);
  }

}

export default new WPDataService();