//import rules from './rbac-rules';


const check = (user, actions, roleURL) => {

    if (!user) return false;

    const role = user[roleURL];
   // const permissions = rules[role];

    if (!role) {
        return false;
    }

    if (role) {
        const actionsList = actions.split(', ');
        for (const action in actionsList) {
            if (role.includes(actionsList[action])) {
                return true;
            }
        }
    }
    return false;
};

const Can = props => (check(props.user, props.perform, process.env.REACT_APP_AUTH0_ROLE_URL) ? props.yes() : props.no());

Can.defaultProps = {
    yes: () => null,
    no: () => null
};

export default Can;