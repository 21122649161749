import { React, Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import { NavLink, withRouter } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import { styled } from '@mui/material/styles';
import { Typography, Grid, Paper, Button } from "@material-ui/core";
import Badge from '@mui/material/Badge';
import Can from '../auth/Can';
import EventLocationDataService from "../services/eventlocation.service";
import EventLocationRequestsIcon from '../img/eventlocation-requests.png';
import EventLocationProfileIcon from '../img/eventlocation-profile.png';



const useStyles = theme => ({

  root: {
    flexGrow: 1,
  },
  paper: {
    height: 200,
    width: 200,
    color: '#00A0B0',
    '&:hover': { boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)' },
    cursor: "pointer"
  },
  paperDisabled: {
    height: 200,
    width: 200,
    color: "#939597",
  },
  paperLink: {
    color: '#00A0B0',
    textDecoration: "none"
  },
  control: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  controlDate: {
    padding: theme.spacing(2),
    color: '#939597'
  },
  comingSoon: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(5),
    color: '#939597'
  },
  tilesAlignment: {
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(2)
  },
  tilesGroupAlignment: {
    paddingBottom: theme.spacing(8)
  },
  subTilesAlignment: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  labelAlignment: {
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(7),
  },
  backButton: {
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',

  },
  formBox: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(-8),
    maxWidth: 600
  },
  startEndDate: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(0)

  },
  weekly: {
    transform: "scale(1.3)"
  },
  timePicker: {
    width: 105,
    marginRight: theme.spacing(0),
  },
  dataPickerSpace: {
    marginLeft: theme.spacing(3)
  }
});


const StyledBadge = styled(Badge)`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-family: IBM Plex Sans, sans-serif;
  position: relative;
  display: inline-block;
  line-height: 1;
  & . {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #07f;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }
`;




class EventLocationContent extends Component {

  constructor(props) {

    super(props);
    // this.getRestaurantProfile = this.getRestaurantProfile.bind(this);
    // this.getBusinessHours = this.getBusinessHours.bind(this);
    this.getEvenLocationRequestsCount = this.getEvenLocationRequestsCount.bind(this);
    this.state = {
      eventLocationRequestsCount: 0,
      isWeekly: false,
      weekStartDay: 0,
      weekEndDay: 0,
      openTimeFrom: new Date(),
      openTimeTo: new Date(),
      startDateNew: null,
      endDateNew: null,
      restaurantName: "",
      createNewPeriodDialogOpen: false,
      startFocus: false,
      endFocus: false,
      loading: true,
      property: null
    }


  }

  createData(day, dayLabel, timeFrom, timeTo, noLunch) {
    return { day, dayLabel, timeFrom, timeTo, noLunch };
  }


  componentDidMount() {
    this.getEvenLocationRequestsCount();
  }

  async getEvenLocationRequestsCount() {
    let token = await this.getToken();
    EventLocationDataService.getEvenLocationRequestsCount(token)
      .then(response => {

        this.setState({
          eventLocationRequestsCount: response.data
        });

      })
      .catch(e => {
        switch (e.response.status) {
          case 403:
            this.props.history.push("/forbidden");
            break;
          default:
            break
        }
        console.log(e);
      });
  }


  async getToken() {
    const { getAccessTokenSilently } = this.props.auth0;
    let token = await getAccessTokenSilently();
    return token;
  }


  render() {

    const { classes } = this.props;
    const { user } = this.props.auth0;
    const { eventLocationRequestsCount } = this.state;


    return (
      <div>

        {/*<Box>
            <Box sx={{ width: 200, marginRight: 0, my: 10, mx: 5 }}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
            <Box sx={{ width: 200, marginRight: 0, my: -30, mx: 35 }}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          </Box>*/}

        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
            <Grid container alignContent="flex-start" spacing={3}>
              <Grid item>
                <Button className={classes.backButton} startIcon={<ArrowBackIosSharpIcon />} component={NavLink} to="/" >Zurück</Button>
              </Grid>
            </Grid>
          </Grid>
          <Can
            user={user}
            perform="EventLocationManager"
            yes={() => (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                <h3 className={classes.labelAlignment}>Eventlocation</h3>
                <Grid container alignContent="flex-start" className={classes.tilesAlignment} spacing={0}>
                  <Grid container item xs={6} sm={6} md={6} lg={6} xl={6} spacing={3} className={classes.tilesGroupAlignment}>


                    <Grid key={0} item>
                      <Paper className={classes.paper} elevation={3} square>
                        <Grid container wrap="wrap" spacing={2} component={NavLink} className={classes.paperLink} to="/eventlocation/profile">
                          <Grid item>
                            <Typography className={classes.control} ><b>Dein Location Profil</b></Typography>
                            <br />
                            <img style={{ margin: "auto", display: "block" }} height={80} src={EventLocationProfileIcon} alt="Dein Location Profil" />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid key={1} item >
                      <StyledBadge color="warning" /*badgeContent={eventLocationRequestsCount} */>
                        <Paper className={classes.paperDisabled} /*elevation={3}*/ square>
                          <Grid container wrap="wrap" spacing={2} /*component={NavLink} className={classes.paperLink} to="/eventlocation/requests"*/ >
                            <Grid item>
                              <Typography className={classes.control}><b>Buchungsanfragen</b></Typography>
                              <br />
                              <img style={{ margin: "auto", display: "block" }} height={80} src={EventLocationRequestsIcon} alt="Buchungsanfragen" />
                              <Typography className={classes.comingSoon}>Coming soon</Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </StyledBadge>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            no={() =>

              <div className={classes.root} >
                <iframe title="Location Vermieten" src="https://mamfito.de/fuer-restaurants/location-vermieten/" frameBorder="0" style={{ border: "none", display: "block", height: "70vh", width: "100vw" }}  ></iframe>
              </div>

            }
          />



        </Grid>


      </div>
    );
  }
}

export default withStyles(useStyles)(withAuth0(withRouter(EventLocationContent)))