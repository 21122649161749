import React, {Fragment} from "react";
import MenuContentLV from "../components/menu-content-lv.component"
import { withAuth0 } from "@auth0/auth0-react";

class LongerValid extends React.Component {
  render() {
    return (
     <Fragment>
        <MenuContentLV />
      
        </Fragment>
    );
  }
}

export default withAuth0(LongerValid);
