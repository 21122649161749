import React, { Fragment } from "react";

import Details from "../components/event-details.component";
import { withStyles } from '@material-ui/core/styles';

import { withAuth0 } from "@auth0/auth0-react";

const useStyles = theme => ({
  profileContainer: {
    marginLeft: theme.spacing(3)
  }
});

class EventDetails extends React.Component {
  render() {

    return (
          <Fragment>
            <Details />
          </Fragment>
  
    );
  }
}

export default withStyles(useStyles)(withAuth0(EventDetails));
