// src/components/nav-bar.js

import React from "react";
import Grid from '@mui/material/Grid';

import AuthNav from "./auth-nav";
import MamfitoLogo from "./logo"


export default function NavBar() {

  return (
    <div>
      <Grid container direction="row" justifyContent="space-around" alignItems="center">
        <Grid item xs={0} sm={0} md={4} lg={4} xl={4} justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }} >
          <MamfitoLogo />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid container direction="row" justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end', lg: 'flex-end', xl: 'flex-end' }} >
            <AuthNav />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
