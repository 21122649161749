import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import MenuDataService from "../services/menu.service";
import { NavLink } from "react-router-dom";
import { TextField, Typography } from "@material-ui/core";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { withAuth0 } from "@auth0/auth0-react";


const useStyles = theme => ({
    profileContainer: {
        marginLeft: theme.spacing(3)
    },
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    startEndDate: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(0)

    },
    weekly: {
        transform: "scale(1.3)"
    }
});


class ProfileContent extends Component {

    constructor(props) {

        super(props);
        this.getRestaurantProfile = this.getRestaurantProfile.bind(this);
        this.state = {
            isWeekly: false, // acts like isLongerValid
            //isLongerValid: false,
            menuType: 0,
            weekStartDay: 0,
            weekEndDay: 0,
            restaurantName: ""
        };

    }


    componentDidMount() {
        this.getRestaurantProfile();
    }

    async getRestaurantProfile() {
        let token = await this.getToken();
        MenuDataService.getRestaurantProfile(token)
            .then(response => {
                this.setState({
                    isWeekly: response.data.isWeekly,
                    menuType: response.data.menuType,
                    weekStartDay: response.data.weekStartDay,
                    weekEndDay: response.data.weekEndDay,
                    restaurantName: response.data.name,
                    profileId: response.data.profileId
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    async updateRestaurantProfile(state) {

        let token = await this.getToken();
        MenuDataService.updateRestaurantProfile(state, token).then(response => {
            console.log(response.data);
        })
            .catch(e => {
                console.log(e);
            });

    }


    async getToken() {
        const { getAccessTokenSilently } = this.props.auth0;
        let token = await getAccessTokenSilently();
        return token;
    }


    handleLongerValidChange = (event) => {
        this.setState({ ...this.state.isWeekly, [event.target.name]: event.target.checked });
        this.updateRestaurantProfile({ isWeekly: event.target.checked });
        //this.state.menuItems.forEach(element => element.dayOfWeek = 0);
    };

    handleMenuTypeChange = (event) => {
        // debugger;
        this.setState({ menuType: event.target.value });
        this.updateRestaurantProfile({ menuType: event.target.value });
        //this.state.menuItems.forEach(element => element.dayOfWeek = 0);
    };

    handleStartDateChange = (e, index) => {
        const { value } = e.target;
        this.updateRestaurantProfile({ weekStartDay: value });
        this.setState({ weekStartDay: value });

    };

    handleEndDateChange = (e, index) => {
        const { value } = e.target;
        // const dateNow = Date.now();
        // const endDate = format(setISODay(dateNow, value, { weekStartsOn: 1 }), "yyyy-MM-dd").toString();
        this.updateRestaurantProfile({ weekEndDay: value });
        this.setState({ weekEndDay: value });
    };



    render() {

        const { classes } = this.props;
        const { user } = this.props.auth0;
        const { name /*picture, email*/ } = user;
        const { isWeekly, menuType, weekStartDay, weekEndDay, restaurantName } = this.state;

        return (

            <div className={classes.profileContainer}>
                <Button startIcon={<ArrowBackIosSharpIcon />} component={NavLink} to="/" >Zurück</Button>
                <br />          <br />
                <Typography variant="h5" gutterBottom>
                    Profil von  {restaurantName}
                </Typography>
                <form className={classes.container} noValidate>
                    <Typography variant="h6" gutterBottom>
                        Unser Mittagsmenü gibt es von:
                    </Typography>
                    <TextField
                        key="startDate"
                        name="startDate"
                        select
                        value={weekStartDay}
                        className={classes.startEndDate}
                        onChange={e => this.handleStartDateChange(e)}
                    >
                        <MenuItem value={1}>Montag</MenuItem>
                        <MenuItem value={2}>Dienstag</MenuItem>
                        <MenuItem value={3}>Mittwoch</MenuItem>
                        <MenuItem value={4}>Donnerstag</MenuItem>
                        <MenuItem value={5}>Freitag</MenuItem>
                        <MenuItem value={6}>Samstag</MenuItem>
                        <MenuItem value={7}>Sonntag</MenuItem>
                    </TextField>
                    <Typography variant="h5" gutterBottom>
                        bis:
                    </Typography>
                    <TextField
                        key="endDate"
                        name="endDate"
                        select
                        className={classes.startEndDate}
                        value={weekEndDay}
                        onChange={e => this.handleEndDateChange(e)}
                    >
                        <MenuItem value={1}>Montag</MenuItem>
                        <MenuItem value={2}>Dienstag</MenuItem>
                        <MenuItem value={3}>Mittwoch</MenuItem>
                        <MenuItem value={4}>Donnerstag</MenuItem>
                        <MenuItem value={5}>Freitag</MenuItem>
                        <MenuItem value={6}>Samstag</MenuItem>
                        <MenuItem value={7}>Sonntag</MenuItem>
                    </TextField>


                </form>

                <br />


                <FormControl variant="standard" sx={{ m: 1, width: 300 }}>
                    <InputLabel id="menuType">Mittagsmenü Option</InputLabel>
                    <Select
                        labelId="menuType"
                        id="menuType"
                        value={menuType}
                        onChange={this.handleMenuTypeChange}
                        label="menuType"
                    >
                        <MenuItem value={0}>Täglich</MenuItem>
                        <MenuItem value={1}>Wöchentlich</MenuItem>
                        <MenuItem value={2}>Mixed</MenuItem>
                    </Select>
                </FormControl>

                <br />
                <br />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isWeekly}
                            onChange={this.handleLongerValidChange}
                            name="isWeekly"
                            className={classes.weekly}
                            color="default"
                        />
                    }
                    label="Menü ist länger gültig"
                />


                <div className="col-md text-center text-md-left">
                    <h3>Restaurant: {name}</h3>
                </div>
            </div>
        );


    }
}

export default withStyles(useStyles)(withAuth0(ProfileContent))