import React, {Fragment} from "react";
import MenuContentNW from "../components/menu-content-nw.component"
import { withAuth0 } from "@auth0/auth0-react";

class NextWeek extends React.Component {
  render() {
    return (
     <Fragment>
        <MenuContentNW />
      
        </Fragment>
    );
  }
}

export default withAuth0(NextWeek);
