import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import MenuDataService from "../services/menu.service";
import WPDataService from "../services/wp.service";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LuggageIcon from '@mui/icons-material/Luggage';
import DeleteIcon from '@material-ui/icons/Delete';
import { Typography } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PublicIcon from '@material-ui/icons/Public';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import { MenuItem } from "@material-ui/core";
import FormGroup from '@material-ui/core/FormGroup';
import { NavLink } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withAuth0 } from "@auth0/auth0-react";
import NumberFormat from 'react-number-format';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Can from "../auth/Can";
import { format, getISOWeek, isBefore } from 'date-fns';
import { de } from 'date-fns/locale';



const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 200,
    width: 200,
  },
  control: {
    padding: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textFieldDish: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    width: 500,
  },
  textFieldPrice: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4),
    width: 100,
  },
  textFieldDay: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    width: 120,
  },
  textFieldMix: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    width: 150,
  },
  textFieldInfo: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 900,
  },
  textFieldVacation: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: 900,
  },
  button: {
    marginRight: theme.spacing(1),
  },

  buttonDataPicker: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  maincontainer: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(3)
  },
  dataPickerSpace: {
    marginLeft: theme.spacing(3)
  },
  periodeDate: {
    width: 130
  }
});

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#00A0B0',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#00A0B0"),
    backgroundColor: "#00A0B0",
    '&:hover': {
      backgroundColor: "#0093A1",
    },
  },
}))(Button);

class MenuContentLV extends Component {

  constructor(props) {

    super(props);

    this.retrieveMenus = this.retrieveMenus.bind(this);

    this.state = {
      menuItems: [],
      additionalInfo: "",
      email: "",
      menuType: null,
      startDate: null,
      endDate: null,
      startDateNew: null,
      endDateNew: null,
      dateRangeChanged: false,
      calendarWeek: getISOWeek(Date.now()),
      name: "",
      published: false,
      restaurantId: null,
      dialogOpen: false,
      vacationDialogOpen: false,
      deleteDialogOpen: false,
      createNewPeriodDialogOpen: false,
      saveChanges: false,
      dialogType: 0,
      deleteItemIndex: null,
      editable: false,
      profileId: "",
      isRequired: true,
      isLastWeekAvailable: false,
      startFocus: false,
      endFocus: false,
      vacation: false,
      vacationText: ""
    };


  }


  componentDidMount() {
    this.retrieveMenus();
  }


  async getToken() {
    const { getAccessTokenSilently } = this.props.auth0;
    let token = await getAccessTokenSilently();
    return token;
  }

  async retrieveMenus() {
    let token = await this.getToken();
    MenuDataService.getLongerValidMenu(token)
      .then(response => {
        if (response.data[0]) {
          this.setState({
            menuItems: response.data[0].menuItems ? response.data[0].menuItems.sort((a, b) => (a.dayOfWeek > b.dayOfWeek) ? 1 : ((b.dayOfWeek > a.dayOfWeek) ? -1 : 0)) : [],
            additionalInfo: response.data[0].additionalInfo,
            email: response.data[0].email,
            menuType: response.data[0].menuType,
            startDate: new Date(response.data[0].startDate),
            endDate: new Date(response.data[0].endDate),
            dateRangeChanged: false,
            name: response.data[0].name,
            published: response.data[0].published,
            restaurantId: response.data[0].id,
            profileId: response.data[0].profileId,
            vacation: response.data[0].vacation,
            vacationText: response.data[0].vacationText === null ? "" : response.data[0].vacationText
          });
          if (response.data[0].menuItems.length > 0) {
            this.setState({
              editable: true
            });
          }

        }
      })
      .catch(e => {
        console.log(e);
      });
  }




  async saveItems(state) {
    let token = await this.getToken();
    state.menuItems.map(x => typeof x.price === 'string' ? x.price = (x.price).replace('.', '').replace('€', '').replace(',', '.') : null);
    state.menuItems.sort((a, b) => (a.dayOfWeek > b.dayOfWeek) ? 1 : ((b.dayOfWeek > a.dayOfWeek) ? -1 : 0));

    if (state.dateRangeChanged) {

      MenuDataService.createNewPeriodeWithItems(this.state, token).then(response => {
        this.retrieveMenus();
        this.setState({
          isRequired: false
        });
        console.log(response.data);
      })
        .catch(e => {
          console.log(e);
        });

    } else {

      MenuDataService.create(state.restaurantId, state, token).then(response => {
        this.retrieveMenus();
        console.log(response.data);
      })
        .catch(e => {
          console.log(e);
        });

    }



  }

  async publishItems(state) {
    state.published = true;
    this.setState({ saveChanges: true, published: true, editable: true });
    this.saveItems(state);

    if (state.menuType === 1) {
      this.updateWP(state);
    } else {
      this.updateWPDaily(state);
    }

  }

  replaceSpecialChars(str) {
    //let result = str.replace(/(\r\n|\n|\r)/gm, "\\n").replace(/\t/g, '').replace(/"/g, '\''); // eslint-disable-line
    //return result;


    let result = str.replace(/(\r\n|\n|\r)/gm, "\n").replace(/\t/g, ''); // Replace line breaks and tabs
    result = result.replace(/[\\/]/g, ''); // Remove forward slashes (/) and backslashes (\)
    result = result.replace(/"/g, '\''); // Replace double quotes with single quotes
    result = result.replace(/\n/g, '');// Remove any remaining newline characters

    return result;

  }

  async updateWP(state) {

    let reqDate = format(new Date(this.state.startDate), "dd.MM.").toString() + " - " + format(new Date(this.state.endDate), "dd.MM.", { locale: de }).toString();
    let reqBodyHeader = '[[\"Mittagsmen\\u00fc gültig: ' + reqDate + '\",\"Preis\"]' // eslint-disable-line
    let reqBody = "";
    let foodType, fT = "";
    let additionalInfo = ',[\"' + this.replaceSpecialChars(state.additionalInfo) + '\",\"#colspan#\"]' // eslint-disable-line
    let reqTail = "]";

    state.menuItems.filter(x => x.deleted !== true).forEach(element => {

      element.vegan ? fT = " (v+.)" : fT = "";
      element.vegetarian ? fT += " (v.)" : fT += "";
      element.lactose ? fT += " (lf.)" : fT += "";
      element.gluten ? fT += " (gf.)" : fT += "";

      fT !== "" ? foodType = fT : foodType = "";

      reqBody += ',[\"' + this.replaceSpecialChars(element.itemName) + foodType + '\",\"' + element.price.toString().replace('.', ',').replace('€', '') + ' \\u20ac\"]'; // eslint-disable-line

    });

    let body;

    if (state.additionalInfo !== "") {
      body = reqBodyHeader + reqBody + additionalInfo + reqTail;
    } else {
      body = reqBodyHeader + reqBody + reqTail;
    }

    let postTitle = this.state.name + " Mittagstisch (" + reqDate + ")";

    let wpContent = {
      "id": this.state.profileId,
      "post_title": postTitle,
      "post_content": body
    }

    WPDataService.updateWP(wpContent);

  }



  async updateWPDaily(state) {

    state.menuItems.sort((a, b) => (a.dayOfWeek > b.dayOfWeek) ? 1 : ((b.dayOfWeek > a.dayOfWeek) ? -1 : 0));

    let reqDate = format(new Date(this.state.startDate), "dd.MM.").toString() + " - " + format(new Date(this.state.endDate), "dd.MM.", { locale: de }).toString();
    let reqBodyHeader = '[[\"Mittagsmen\\u00fc gültig: ' + reqDate + '\",\"Preis\"]' // eslint-disable-line
    let additionalInfo = ',[\"' + this.replaceSpecialChars(state.additionalInfo) + '\",\"#colspan#\"]' // eslint-disable-line
    let reqTail = "]";


    let emptyItems = this.createReqBodyForUpdateWp(state.menuItems.filter(x => x.dayOfWeek === 0 && x.deleted !== true), "");
    let moItems = this.createReqBodyForUpdateWp(state.menuItems.filter(x => x.dayOfWeek === 1 && x.deleted !== true), "Montag");
    let diItems = this.createReqBodyForUpdateWp(state.menuItems.filter(x => x.dayOfWeek === 2 && x.deleted !== true), "Dienstag");
    let miItems = this.createReqBodyForUpdateWp(state.menuItems.filter(x => x.dayOfWeek === 3 && x.deleted !== true), "Mittwoch");
    let doItems = this.createReqBodyForUpdateWp(state.menuItems.filter(x => x.dayOfWeek === 4 && x.deleted !== true), "Donnerstag");
    let frItems = this.createReqBodyForUpdateWp(state.menuItems.filter(x => x.dayOfWeek === 5 && x.deleted !== true), "Freitag");
    let saItems = this.createReqBodyForUpdateWp(state.menuItems.filter(x => x.dayOfWeek === 6 && x.deleted !== true), "Samstag");
    let soItems = this.createReqBodyForUpdateWp(state.menuItems.filter(x => x.dayOfWeek === 7 && x.deleted !== true), "Sonntag");
    let weItems = this.createReqBodyForUpdateWp(state.menuItems.filter(x => x.dayOfWeek === 9 && x.deleted !== true), "Die ganze Woche");

    let body;
    let reqBody = emptyItems + moItems + diItems + miItems + doItems + frItems + saItems + soItems + weItems;

    if (state.additionalInfo !== "") {
      body = reqBodyHeader + reqBody + additionalInfo + reqTail;
    } else {
      body = reqBodyHeader + reqBody + reqTail;
    }

    let postTitle = this.state.name + " Mittagstisch (" + reqDate + ")";

    let wpContent = {
      "id": this.state.profileId,
      "post_title": postTitle,
      "post_content": body
    }

    WPDataService.updateWP(wpContent);

  }


  async updateVacationWP(state) {

    let reqDate = format(new Date(this.state.startDate), "dd.MM.").toString() + " - " + format(new Date(this.state.endDate), "dd.MM.", { locale: de }).toString();
    let reqBodyHeader = '[[\"Zeitraum vom: ' + reqDate + '\"]' // eslint-disable-line
    let vacationText = ',[\"' + this.replaceSpecialChars(state.vacationText) + '\"]' // eslint-disable-line
    let reqTail = "]";

    let body = reqBodyHeader + vacationText + reqTail;
    let postTitle = this.state.name + " Mittagstisch (" + reqDate + ")";

    let wpContent = {
      "id": this.state.profileId,
      "post_title": postTitle,
      "post_content": body
    }

    WPDataService.updateWP(wpContent);

  }




  createReqBodyForUpdateWp = (arr, day) => {

    let reqBody = "";
    let foodType, fT = "";
    let dayBody = "";

    if (arr.length > 0) {

      arr.forEach(element => {

        element.vegan ? fT = " (v+.)" : fT = "";
        element.vegetarian ? fT += " (v.)" : fT += "";
        element.lactose ? fT += " (lf.)" : fT += "";
        element.gluten ? fT += " (gf.)" : fT += "";

        fT !== "" ? foodType = fT : foodType = "";
        reqBody += ',[\"' + this.replaceSpecialChars(element.itemName) + foodType + '\",\"' + element.price.toString().replace('.', ',').replace('€', '') + ' \\u20ac\"]'; // eslint-disable-line

      });

      dayBody = ',[\"' + day + '\",\"#colspan#\"]' + reqBody; // eslint-disable-line

    }

    return dayBody;
  }


  saveItemsDialog = (state) => {
    //this.setState({ published: true });
    this.setState({ saveChanges: false, dialogOpen: true, dialogType: 1, isRequired: true });
    this.saveItems(state);
  }

  publishVacationDialog = (state) => {
    this.setState({ vacationDialogOpen: true });
    this.updateVacation(state)
  }


  publishItemsDialog = (state) => {
    //this.setState({ published: true });
    //this.saveItems(state);
    this.setState({ saveChanges: false, dialogOpen: true, dialogType: 2 });

  }

  deleteItemsDialog = (index) => {
    //this.setState({ published: true });
    this.setState({ deleteDialogOpen: true, deleteItemIndex: index });

  }

  editFields = () => {
    this.setState({ editable: false });
  }

  doneEdit = () => {
    this.retrieveMenus();
    this.setState({ editable: true, isRequired: true });
  }

  async updateVacation(state) {

    let payload = {
      profileId: state.profileId,
      vacation: state.vacation,
      vacationText: state.vacationText,
      startDate: state.startDate,
      endDate: state.endDate
    }

    let token = await this.getToken();
    MenuDataService.updateVacation(payload, token).then(response => {
      //console.log(response.data);
      this.updateVacationWP(state);
    })
      .catch(e => {
        console.log(e);
      });

  }




  async deleteMenuItem(item) {
    let token = await this.getToken();
    if (item) {
      MenuDataService.deleteMenuItem(item, token).then(response => {
        console.log(response.data);
      })
        .catch(e => {
          console.log(e);
        });
    }
    this.setState({ isRequired: !this.state.menuItems.every(this.isFieldRequired) });

  }

  handleDialogClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ dialogOpen: false, deleteDialogOpen: false, createNewPeriodDialogOpen: false });
    //this.setState({ saveChanges: false });
  };

  handleDialogCloseNewDate = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ dialogOpen: false, deleteDialogOpen: false, createNewPeriodDialogOpen: false, startDateNew: null, endDateNew: null });
    //this.setState({ saveChanges: false });
  };


  // handle input change
  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.menuItems];
    list[index][name] = value;
    this.setState({ isRequired: !this.state.menuItems.every(this.isFieldRequired) });
    this.setState({ menuItems: list });
  };

  isFieldRequired = (el) => {
    return (el.itemName !== "" && el.price !== "" && !this.startEndDateRule());
  }

  isFieldReq = (el) => {
    return (el.itemName !== "" && el.price !== "");
  }


  handleAdditionalInfoChange = (e, index) => {
    const { value } = e.target;
    this.setState({ isRequired: !this.state.menuItems.every(this.isFieldRequired) });
    this.setState({ additionalInfo: value });
  };

  // handle click event of the Remove button
  handleRemoveClick = () => {

    const list = [...this.state.menuItems];
    let index = this.state.deleteItemIndex;

    if (list[index].price === '' || list[index].itemName === '') {
      list.splice(index, 1);
      this.setState({ deleteDialogOpen: false, isRequired: false, menuItems: list });
    } else {
      list[index]["deleted"] = true;
      this.setState({ deleteDialogOpen: false, isRequired: false, menuItems: list });
    }

    //this.state.menuItems[this.state.deleteItemIndex].deleted = true
    //this.setState({ deleteDialogOpen: false, isRequired: false });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    this.setState({ menuItems: [...this.state.menuItems, { itemName: "", price: "", dayOfWeek: 0, restaurantId: this.state.restaurantId, vegan: false, vegetarian: false, lactose: false, gluten: false }], isRequired: true });
  };


  handleDayChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.menuItems];
    list[index][name] = value;
    this.setState({ isRequired: !this.state.menuItems.every(this.isFieldRequired) });
    this.setState({ menuItems: list });
    //this.setDay(value);
  };


  handleStartDateChange = (val) => {
    var dateRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/; //dd.MM.yyyy
    this.setState({ startDate: val, dateRangeChanged: true });


    if (this.state.menuItems.every(this.isFieldReq)) {
      if ((val === null || isNaN(val) || this.state.endDate === null)) {
        this.setState({ isRequired: true });
      } else if (isBefore(new Date(this.state.endDate), new Date(val))) {
        this.setState({ isRequired: true });
      } else if (!dateRegex.test(format(new Date(val), "dd.MM.yyyy", { locale: de }).toString())) {
        this.setState({ isRequired: true });
      } else {
        this.setState({ isRequired: false });
      }
    } else {
      this.setState({ isRequired: true });
    }


  }

  handleEndDateChange = (val) => {
    var dateRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/; //dd.MM.yyyy
    this.setState({ endDate: val, dateRangeChanged: true });


    if (this.state.menuItems.every(this.isFieldReq)) {
      if ((this.state.startDate === null || val === null || isNaN(val))) {
        this.setState({ isRequired: true });
      } else if (isBefore(new Date(val), new Date(this.state.startDate))) {
        this.setState({ isRequired: true });
      } else if (!dateRegex.test(format(new Date(val), "dd.MM.yyyy", { locale: de }).toString())) {
        this.setState({ isRequired: true });
      } else {
        this.setState({ isRequired: false });
      }
    } else {
      this.setState({ isRequired: true });
    }


  }

  handleStartDateNewChange = (val) => {

    this.setState({ startDateNew: val, isRequired: false });
  }

  handleEndDateNewChange = (val) => {

    this.setState({ endDateNew: val, isRequired: false });
  }



  handleFoodTypeChange = (e, index) => {
    const { name, checked } = e.target;
    const list = [...this.state.menuItems];
    list[index][name] = checked;
    if (name === "vegan" && checked) {
      list[index]["lactose"] = checked
    }
    if (name === "lactose" && list[index]["vegan"]) {
      list[index]["lactose"] = !checked
    }
    this.setState({ menuItems: list });
    this.setState({ isRequired: !this.state.menuItems.every(this.isFieldRequired) });
  };

  handleVacationChange = (event) => {
    this.setState({ vacation: event.target.checked });
    if (!event.target.checked) {
      this.setState({ editable: false, isRequired: false });
    } else {
      this.setState({ editable: true });
    }
  }


  handleVacationTextChange = (event) => {
    const { value } = event.target;
    this.setState({ vacationText: value });
  }



  async createNewPeriodDialog(state) {


    this.setState({ createNewPeriodDialogOpen: true });

    /* MenuDataService.isLastWeekMaintained("cw", state, token).then(response => {
       //console.log(response.data);
       this.setState({ isLastWeekAvailable: response.data, createNewPeriodDialogOpen: true });
 
     })
       .catch(e => {
         console.log(e);
       }); */

  }

  async createNewPeriod(state) {
    let token = await this.getToken();

    this.setState({ createNewPeriodDialogOpen: false });

    MenuDataService.createNewPeriode(this.state.startDateNew, this.state.endDateNew, token).then(response => {
      this.retrieveMenus();
      this.setState({
        isRequired: false
      });
      //console.log(response.data);
    })
      .catch(e => {
        console.log(e);
      });

  }

  handleStartDateTouch = () => {
    this.setState({ startFocus: true });
  };

  handleEndDateTouch = () => {
    this.setState({ endFocus: true });
  };

  handleDateHelperText = (focus, params) => {

    var dateRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/; //dd.MM.yyyy


    if (focus && (params.inputProps.value === undefined || params.inputProps.value === "")) {
      return "Dieses Feld ist erforderlich"
    } else if (isBefore(new Date(this.state.endDate), new Date(this.state.startDate))) {
      return "Enddatum soll nach dem Startdatum liegen"
    } else if (!dateRegex.test(params.inputProps.value)) {
      return "Falsches Datumsformat"
    } else {
      return "";
    }

  }

  handleDateError = (focus, params) => {

    var dateRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/; //dd.MM.yyyy

    if (focus && (params.inputProps.value === undefined || params.inputProps.value === "")) {
      return true
    } else if (isBefore(new Date(this.state.endDate), new Date(this.state.startDate))) {
      return true
    } else if (!dateRegex.test(params.inputProps.value)) {
      return true
    } else {
      return false;
    }

  }


  startEndDateRule = () => {

    var dateRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/; //dd.MM.yyyy

    if (!((isNaN(this.state.startDate) || this.state.startDate === null)) && !((isNaN(this.state.endDate) || this.state.endDate === null))) {

      let startDate = format(this.state.startDate, "dd.MM.yyyy", { locale: de }).toString();
      let endDate = format(this.state.endDate, "dd.MM.yyyy", { locale: de }).toString();

      if (isBefore(this.state.endDate, this.state.startDate)) {
        return true;
      } else if (!dateRegex.test(startDate)) {
        return true;
      } else if (!dateRegex.test(endDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true
    };


  }




  render() {

    const { classes } = this.props;
    const { menuItems, additionalInfo, menuType, startDate, endDate, startDateNew, endDateNew, dialogOpen, deleteDialogOpen, createNewPeriodDialogOpen, vacationDialogOpen, saveChanges, dialogType, editable, isRequired, startFocus, endFocus, vacation, vacationText } = this.state;
    const { user } = this.props.auth0;

    return (
      <div className={classes.maincontainer}>
        <Button startIcon={<ArrowBackIosSharpIcon />} component={NavLink} to="/lunch" >Zurück</Button>
        <br /> <br /> <br />
        <Typography variant="h4" gutterBottom>
          Was gibt's leckeres für deine Gäste?
        </Typography>
        <br /> <br />
        <Grid container alignContent="flex-start" className={classes.tilesAlignment} spacing={3}>
          <Grid key={0} item>
            {/* <Typography variant="h5" gutterBottom>
              Kalenderwoche {calendarWeek} ({format(new Date(startDate), "dd").toString()} - {format(new Date(endDate), "dd MMMM", { locale: de }).toString()})
            </Typography>  */}

            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
              <DatePicker
                mask={'__.__.____'}
                label="Gültig von"
                value={startDate}
                disabled={editable}
                /*minDate={new Date("2020-01-01")}
                maxDate={new Date("2030-01-01")}*/
                onChange={(newValue) => this.handleStartDateChange(newValue)}
                renderInput={(params) => <TextField className={classes.periodeDate}
                  {...params}
                  required
                  onFocus={() => this.handleStartDateTouch()}
                  helperText={this.handleDateHelperText(startFocus, params)}
                  error={this.handleDateError(startFocus, params)} />}
              />
              <span className={classes.dataPickerSpace} />
              <DatePicker
                mask={'__.__.____'}
                label="bis"
                value={endDate}
                disabled={editable}
                onChange={(newValue) => this.handleEndDateChange(newValue)}
                renderInput={(params) => <TextField className={classes.periodeDate}
                  {...params}
                  required
                  onFocus={() => this.handleEndDateTouch()}
                  helperText={this.handleDateHelperText(endFocus, params)}
                  error={this.handleDateError(endFocus, params)} />}
              />

            </LocalizationProvider>

          </Grid>
          {!vacation ?
            <Grid key={1} item>
              {editable ?
                <ColorButton
                  variant="contained"
                  color="default"
                  className={classes.buttonDataPicker}
                  size="small"
                  margin={3}
                  onClick={() => this.editFields()}
                  endIcon={<EditIcon />}
                >
                  Bearbeiten
                </ColorButton>
                :
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  margin={3}
                  onClick={() => this.doneEdit()}
                  className={classes.buttonDataPicker}
                  endIcon={<CloseIcon />}
                >
                  Bearbeitung Schließen
                </Button>}
            </Grid>
            : null}
        </Grid>



        <br />
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Für diesen Zeitraum geschlossen</Typography>
          <AntSwitch checked={vacation} onChange={this.handleVacationChange} />
          <Typography>Ja</Typography>
        </Stack>
        <br /> <br /> <br />
        {menuItems && menuItems.map((x, i) => {
          return (
            <div key={"item" + i}>  {!vacation ?
              <div key={"item" + i}>
                {!x.deleted ?
                  <form key={"dishForm" + i} className={classes.formContainer}  >
                    {menuType === 0 ?
                      <TextField
                        key={"day" + i}
                        name="dayOfWeek"
                        select
                        label="Tag"
                        value={x.dayOfWeek}
                        defaultValue="0"
                        disabled={editable}
                        className={classes.textFieldDay}
                        onChange={e => this.handleDayChange(e, i)}
                      >
                        <MenuItem value={0}>-</MenuItem>
                        <MenuItem value={1}>Montag</MenuItem>
                        <MenuItem value={2}>Dienstag</MenuItem>
                        <MenuItem value={3}>Mittwoch</MenuItem>
                        <MenuItem value={4}>Donnerstag</MenuItem>
                        <MenuItem value={5}>Freitag</MenuItem>
                        <MenuItem value={6}>Samstag</MenuItem>
                        <MenuItem value={7}>Sonntag</MenuItem>
                      </TextField>
                      : menuType === 1 ? null : menuType === 2 ?
                        <TextField
                          key={"day" + i}
                          name="dayOfWeek"
                          select
                          label="Tag"
                          value={x.dayOfWeek}
                          defaultValue="0"
                          disabled={editable}
                          className={classes.textFieldMix}
                          onChange={e => this.handleDayChange(e, i)}
                        >
                          <MenuItem value={0}>-</MenuItem>
                          <MenuItem value={9}>Die ganze Woche</MenuItem>
                          <MenuItem value={1}>Montag</MenuItem>
                          <MenuItem value={2}>Dienstag</MenuItem>
                          <MenuItem value={3}>Mittwoch</MenuItem>
                          <MenuItem value={4}>Donnerstag</MenuItem>
                          <MenuItem value={5}>Freitag</MenuItem>
                          <MenuItem value={6}>Samstag</MenuItem>
                          <MenuItem value={7}>Sonntag</MenuItem>
                        </TextField>
                        : null}

                    <TextField
                      key={"dish" + i}
                      name="itemName"
                      label="Gericht"
                      type="textField"
                      required
                      multiline
                      maxRows={2}
                      value={x.itemName}
                      disabled={editable}
                      onChange={e => this.handleInputChange(e, i)}
                      className={classes.textFieldDish}
                      helperText={x.itemName === undefined || x.itemName === "" ? "Dieses Feld ist erforderlich" : ""}
                      error={x.itemName === undefined || x.itemName === "" ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <NumberFormat
                      customInput={TextField}
                      key={"price" + i}
                      name="price"
                      label="Preis"
                      placeholder={'0,00€'}
                      required
                      value={x.price}
                      type="text"
                      disabled={editable}
                      className={classes.textFieldPrice}
                      helperText={x.price === undefined || x.price === "" ? "Dieses Feld ist erforderlich" : ""}
                      error={x.price === undefined || x.price === "" ? true : false}
                      onChange={e => this.handleInputChange(e, i)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      suffix='€'
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      fixedDecimalScale={true}
                      allowEmptyFormatting={false}
                      isNumericString={false}
                      allowNegative={false}
                    />

                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox color="default" disabled={editable} checked={x.vegan} onChange={e => this.handleFoodTypeChange(e, i)} name="vegan" />}
                        label="Vegan"
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" disabled={editable} checked={x.vegetarian} onChange={e => this.handleFoodTypeChange(e, i)} name="vegetarian" />}
                        label="Vegetarisch"
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" disabled={editable} checked={x.gluten} onChange={e => this.handleFoodTypeChange(e, i)} name="gluten" />}
                        label="Glutenfrei"
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" disabled={editable} checked={x.lactose} onChange={e => this.handleFoodTypeChange(e, i)} name="lactose" />}
                        label="Laktosefrei"
                      />
                    </FormGroup>
                    <Tooltip title="Löschen">
                      <IconButton aria-label="delete" disabled={editable} className={classes.margin} onClick={() => this.deleteItemsDialog(i)}>
                        <DeleteIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>

                  </form>
                  : null}
              </div>
              : null}
            </div>
          );
        })}
        {!vacation ?
          <div>
            <IconButton aria-label="add" onClick={this.handleAddClick} disabled={editable}>
              <AddCircleIcon style={!editable ? { color: "#F5DF4D", fontSize: 60 } : { fontSize: 60 }} />
              <Typography variant="h6">   Gericht einfügen</Typography>
            </IconButton>
            <br />
            <br />
            <TextField
              id="additionalInfo"
              label="Zusatzinformation (Welche weiteren Informationen zum Mittagsmenü möchtest du deinen Gästen noch mitteilen)"
              multiline
              rows={5}
              value={additionalInfo}
              disabled={editable}
              onChange={this.handleAdditionalInfoChange}
              className={classes.textFieldInfo}
              variant="standard"
            />

            <br />
            <br />
            {!editable ?
              <Fragment>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  margin={3}
                  onClick={() => this.saveItemsDialog(this.state)}
                  endIcon={<FileCopyIcon />}
                  disabled={isRequired}
                >
                  Entwurf Speichern
                </Button>
                <ColorButton
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.publishItemsDialog(this.state)}
                  endIcon={<PublicIcon />}
                  disabled={isRequired}
                >
                  Veröffentlichen
                </ColorButton>
              </Fragment>
              :
              null}
          </div>
          :
          <div>
            <TextField
              id="vacationText"
              label="Warum ist der Zeitraum geschlossen? Wann seid ihr zurück? (Wird statt eurem Menu angezeigt)"
              multiline
              rows={5}
              value={vacationText}
              onChange={this.handleVacationTextChange}
              className={classes.textFieldVacation}
              variant="standard"
            />
            <br /><br />
            <ColorButton
              variant="contained"
              color="primary"
              className={classes.button}
              margin={3}
              onClick={() => this.publishVacationDialog(this.state)}
              endIcon={<LuggageIcon />}

            >
              Urlaub eintragen
            </ColorButton>
          </div>}
        <Dialog
          open={dialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {saveChanges ?
            <Fragment>
              <DialogTitle id="alert-dialog-title1">{"Danke!"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description1" >
                  Dein Menü ist erfolgreich veröffentlich worden! Deine Gäste werden sich freuen!
                </DialogContentText>
                <span style={{ fontSize: 70, textAlign: "center", display: "block" }}>&#128522;</span>
                <DialogContentText>
                <Can
                      user={user}
                      perform="TipPaid"
                      no={() => (
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography ><b>Willst du mamfito unterstützen?</b></Typography>
                          <br />
                          <ColorButton
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            margin={3}
                            component={NavLink} to="/tip"
                          >
                            Ja
                          </ColorButton>
                          <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            margin={3}
                            onClick={this.handleDialogClose}
                            component={NavLink} to="/lunch"
                          >
                            Nein, Schließen
                          </Button>
                        </Grid>
                      )}
                    />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleDialogClose} component={NavLink} to="/lunch" color="primary" autoFocus>
                  Schließen
                </Button>
              </DialogActions>
            </Fragment>
            :
            dialogType === 1 ?
              <Fragment>
                <DialogTitle id="alert-dialog-title2">Entwurf erfolgreich gespeichert!</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description2">
                    Der Entwurf ist gespeichert! Jetzt auf mamfito.de veröffentlichen?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <ColorButton
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => this.publishItems(this.state)}
                    endIcon={<PublicIcon />}
                  >
                    Jetzt Veröffentlichen
                  </ColorButton>
                  <Button onClick={this.handleDialogClose} color="primary" autoFocus>
                    Später
                  </Button>
                </DialogActions>
              </Fragment>
              :
              <Fragment>
                <DialogTitle id="alert-dialog-title">Mittagsmenü jetzt veröffentlichen?</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Willst du das Mittagsmenü für die aktuelle Woche jetzt veröffentlichen?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.publishItems(this.state)} color="primary" autoFocus>
                    Ja
                  </Button>
                  <Button onClick={this.handleDialogClose} color="secondary">
                    Nein
                  </Button>
                </DialogActions>
              </Fragment>}
        </Dialog>
        <Dialog
          open={deleteDialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Fragment>
            <DialogTitle id="alert-dialog-title">Gericht löschen?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Willst du das Gericht wirklich löschen?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleRemoveClick()} color="primary" autoFocus>
                Ja
              </Button>
              <Button onClick={this.handleDialogClose} color="secondary">
                Nein
              </Button>
            </DialogActions>
          </Fragment>
        </Dialog>
        <Dialog
          open={createNewPeriodDialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          <Fragment>
            <DialogTitle id="alert-dialog-title">Neues Menu erstellen</DialogTitle>
            <DialogContent>


              <LocalizationProvider dateAdapter={AdapterDateFns} locale={de}>

                <DatePicker
                  mask={'__.__.____'}
                  label="Gültig von"
                  value={startDateNew}
                  onChange={(newValue) => this.handleStartDateNewChange(newValue)}
                  renderInput={(params) => <TextField className={classes.periodeDate} {...params} />}
                />
                <span className={classes.dataPickerSpace} />
                <DatePicker
                  mask={'__.__.____'}
                  label="bis"
                  value={endDateNew}
                  onChange={(newValue) => this.handleEndDateNewChange(newValue)}
                  renderInput={(params) => <TextField className={classes.periodeDate} {...params} />}
                />

              </LocalizationProvider>

              <br />
              <br />

              <DialogContentText id="alert-dialog-description">
                Die aktuellen Einträge werden überschrieben
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.createNewPeriod(this.state)} color="primary" autoFocus>
                Erstellen
              </Button>
              <Button onClick={this.handleDialogCloseNewDate} color="secondary">
                Abbrechen
              </Button>
            </DialogActions>
          </Fragment>

        </Dialog>
        <Dialog
          open={vacationDialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-vacation"
          aria-describedby="alert-dialog-vacation-content"
        >
          <Fragment>
            <DialogTitle id="alert-dialog-vacation">{"Danke!"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-vacation-content" >
                Dein Urlaub wurde erfolgreich erfasst!
              </DialogContentText>
              <span style={{ fontSize: 70, textAlign: "center", display: "block" }}>&#128522;</span>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose} component={NavLink} to="/lunch" color="primary" autoFocus>
                Schließen
              </Button>
            </DialogActions>
          </Fragment>
        </Dialog>


      </div>
    );


  }
}

export default withStyles(useStyles)(withAuth0(MenuContentLV))