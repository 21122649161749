import { React, Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import { NavLink, withRouter } from "react-router-dom";
import {
  startOfWeek,
  endOfWeek,
  format,
  nextMonday,
  nextSunday,
  isBefore
} from "date-fns";
import { withAuth0 } from "@auth0/auth0-react";
import MenuDataService from "../services/menu.service";
import WPDataService from "../services/wp.service";
import { TextField, Typography, Grid, Paper, Button } from "@material-ui/core";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Switch from "@mui/material/Switch";
import DialogTitle from "@material-ui/core/DialogTitle";
import { de } from "date-fns/locale";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FeaturedListingImage from "../img/featured-listing.png";
import CardMedia from "@mui/material/CardMedia";
import UploadFiles from "./upload-files.component";
import Can from "../auth/Can";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 200,
    width: 200,
    color: "#00A0B0",
    "&:hover": {
      boxShadow:
        "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
    },
    cursor: "pointer",
  },

  paperDisabled: {
    height: 200,
    width: 200,
    color: "#939597",
  },

  paperLink: {
    color: "#00A0B0",
    textDecoration: "none",
  },
  control: {
    padding: theme.spacing(2),
  },
  controlDate: {
    padding: theme.spacing(2),
    color: "#939597",
  },
  tilesAlignment: {
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(2),
  },
  tilesGroupAlignment: {
    paddingBottom: theme.spacing(8),
  },
  subTilesAlignment: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  labelAlignment: {
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(7),
  },
  backButton: {
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formBox: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(-8),
    maxWidth: 600,
  },
  startEndDate: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(0),
  },
  weekly: {
    transform: "scale(1.3)",
  },
  timePicker: {
    width: 105,
    marginRight: theme.spacing(0),
  },
  dataPickerSpace: {
    marginLeft: theme.spacing(3),
  },

  dialogActionButton: {
    justifyContent: "center",
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#00A0B0"),
    backgroundColor: "#00A0B0",
    '&:hover': {
      backgroundColor: "#0093A1",
    },
  },
}))(Button);


class LunchContent extends Component {
  constructor(props) {
    super(props);
    this.getRestaurantProfile = this.getRestaurantProfile.bind(this);
    this.getBusinessHours = this.getBusinessHours.bind(this);
    this.state = {
      isWeekly: false,
      weekStartDay: 0,
      weekEndDay: 0,
      openTimeFrom: new Date(),
      openTimeTo: new Date(),
      startDateNew: null,
      endDateNew: null,
      voted: false,
      restaurantName: "",
      createNewPeriodDialogOpen: false,
      featuredListingDialogOpen: false,
      startFocus: false,
      endFocus: false,
      loading: true,
      property: null,
      openingTime: [
        this.createData(0, "Montag", null, null, true),
        this.createData(1, "Dienstag", null, null, true),
        this.createData(2, "Mittwoch", null, null, true),
        this.createData(3, "Donnerstag", null, null, true),
        this.createData(4, "Freitag", null, null, true),
        this.createData(5, "Samstag", null, null, true),
        this.createData(6, "Sonntag", null, null, true),
      ],
    };
  }

  createData(day, dayLabel, timeFrom, timeTo, noLunch) {
    return { day, dayLabel, timeFrom, timeTo, noLunch };
  }

  componentDidMount() {
    this.getRestaurantProfile();
    this.getBusinessHours();
  }

  async getRestaurantProfile() {
    let token = await this.getToken();
    MenuDataService.getRestaurantProfile(token)
      .then((response) => {
        this.setState({
          isWeekly: response.data.isWeekly,
          weekStartDay: response.data.weekStartDay,
          weekEndDay: response.data.weekEndDay,
          openTimeFrom: response.data.openTimeFrom,
          openTimeTo: response.data.openTimeTo,
          restaurantName: response.data.name,
          profileId: response.data.profileId,
          loading: false,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async getBusinessHours() {
    let token = await this.getToken();
    MenuDataService.getBusinessHours(token)
      .then((response) => {
        let oH = this.state.openingTime.map((x) => {
          let y = response.data.find((element) => element.day === x.day);
          return { ...x, ...y };
        });

        this.setState({
          openingTime: oH,
        });
        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async updateRestaurantProfile(state) {
    let token = await this.getToken();
    MenuDataService.updateRestaurantProfile(state, token)
      .then((response) => {
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async getToken() {
    const { getAccessTokenSilently } = this.props.auth0;
    let token = await getAccessTokenSilently();
    return token;
  }

  async updateBusinessHours() {
    let token = await this.getToken();
    let bH = this.state.openingTime
      .map(({ dayLabel, ...rest }) => {
        return rest;
      })
      .map((ele) => {
        return { profileId: this.state.profileId, ...ele };
      });

    MenuDataService.updateBusinessHours(bH, token)
      .then((response) => {
        this.getBusinessHours();
        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    let getDayAbbr = (day) => {
      switch (day) {
        case 0:
          return "Mo";
        case 1:
          return "Tu";
        case 2:
          return "We";
        case 3:
          return "Th";
        case 4:
          return "Fr";
        case 5:
          return "Sa";
        case 6:
          return "Su";
        default:
          break;
      }
    };

    let reqBodyHeader = '["'; // eslint-disable-line
    let reqTail = '"],[ "UTC ": "+1 ", "Timezone ": "Europe/Berlin "]';
    let arr = [];
    this.state.openingTime
      .filter((x) => x.noLunch === true)
      .forEach((element) => {
        if (element.timeFrom && element.timeTo) {
          arr.push(
            getDayAbbr(element.day) +
            " " +
            format(new Date(element.timeFrom), "HH:mm") +
            "-" +
            format(new Date(element.timeTo), "HH:mm")
          );
        }
      });

    let businessHours = reqBodyHeader + arr.join('","') + reqTail;
    let businessHoursContent = {
      restaurant_id: this.state.profileId,
      business_hours: businessHours,
    };

    if (arr.length > 0) {
      //ToDo additional logic using noLunch attribute
      WPDataService.updateBusinessHours(businessHoursContent);
    }
  }

  handleIsClosed = (e, index) => {
    const list = [...this.state.openingTime];
    list[index]["noLunch"] = e.target.checked;
    this.setState({ openingTime: list });
    this.updateBusinessHours();
  };

  handleDayWeekChange = (event) => {
    this.setState({
      ...this.state.isWeekly,
      [event.target.name]: event.target.checked,
    });
    this.updateRestaurantProfile({ isWeekly: event.target.checked });
    //this.state.menuItems.forEach(element => element.dayOfWeek = 0);
  };

  handleStartDateChange = (e, index) => {
    const { value } = e.target;
    this.updateRestaurantProfile({ weekStartDay: value });
    this.setState({ weekStartDay: value });
  };

  handleEndDateChange = (e, index) => {
    const { value } = e.target;
    // const dateNow = Date.now();
    // const endDate = format(setISODay(dateNow, value, { weekStartsOn: 1 }), "yyyy-MM-dd").toString();
    this.updateRestaurantProfile({ weekEndDay: value });
    this.setState({ weekEndDay: value });
  };

  handleTimeChangeFrom = (e, index) => {
    // const { value } = e.getTime();
    // const dateNow = Date.now();
    // const endDate = format(setISODay(dateNow, value, { weekStartsOn: 1 }), "yyyy-MM-dd").toString();
    if (e && e.getTime()) {
      const list = [...this.state.openingTime];
      list[index]["timeFrom"] = e;
      this.setState({ openingTime: list });

      this.updateBusinessHours();
      //this.updateRestaurantProfile({ openTimeFrom: e });
    }
  };

  handleTimeChangeTo = (e, index) => {
    // const { value } = e.toLocaleString();
    // const dateNow = Date.now();
    // const endDate = format(setISODay(dateNow, value, { weekStartsOn: 1 }), "yyyy-MM-dd").toString();
    if (e && e.getTime()) {
      const list = [...this.state.openingTime];
      list[index]["timeTo"] = e;
      this.setState({ openingTime: list });

      this.updateBusinessHours();
      //this.updateRestaurantProfile({ openTimeTo: e });
    }
  };

  createNewPeriodDialog = () => {
    this.setState({ createNewPeriodDialogOpen: true });
  };

  featuredListingDialog = () => {
    this.setState({ featuredListingDialogOpen: true, voted: false });
  };

  handleDialogClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      dialogOpen: false,
      deleteDialogOpen: false,
      createNewPeriodDialogOpen: false,
      featuredListingDialogOpen: false,
      endFocus: false,
      startFocus: false,
    });
    //this.setState({ saveChanges: false });
  };

  handleDialogCloseNewDate = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      dialogOpen: false,
      deleteDialogOpen: false,
      createNewPeriodDialogOpen: false,
      startDateNew: null,
      endDateNew: null,
      endFocus: false,
      startFocus: false,
    });
    //this.setState({ saveChanges: false });
  };

  handleStartDateNewChange = (val) => {
    this.setState({ startDateNew: val, isRequired: false });
  };

  handleEndDateNewChange = (val) => {
    this.setState({ endDateNew: val, isRequired: false });
  };

  async createNewPeriod(state) {
    let token = await this.getToken();

    this.setState({ createNewPeriodDialogOpen: false });

    MenuDataService.createNewPeriode(
      this.state.startDateNew,
      this.state.endDateNew,
      token
    )
      .then((response) => {
        this.setState({
          isRequired: false,
        });

        this.props.history.push("/longervalid");

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onSurvey = async (surveyValue) => {
    let token = await this.getToken();
    this.setState({ voted: true });
    let surveyReult = {
      featuredListing: surveyValue,
    };
    MenuDataService.submitSurvey([surveyReult], token)
      .then((response) => {
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleStartDateTouch = () => {
    this.setState({ startFocus: true });
  };

  handleEndDateTouch = () => {
    this.setState({ endFocus: true });
  };

  handleDateHelperText = (focus, params) => {
    var dateRegex =
      /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/; //dd.MM.yyyy

    if (
      focus &&
      (params.inputProps.value === undefined || params.inputProps.value === "")
    ) {
      return "Dieses Feld ist erforderlich";
    } else if (isBefore(this.state.endDateNew, this.state.startDateNew)) {
      return "Enddatum soll nach dem Startdatum liegen";
    } else if (focus && !dateRegex.test(params.inputProps.value)) {
      return "Falsches Datumsformat";
    } else {
      return "";
    }
  };

  handleDateError = (focus, params) => {
    var dateRegex =
      /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/; //dd.MM.yyyy

    if (
      focus &&
      (params.inputProps.value === undefined || params.inputProps.value === "")
    ) {
      return true;
    } else if (isBefore(this.state.endDateNew, this.state.startDateNew)) {
      return true;
    } else if (focus && !dateRegex.test(params.inputProps.value)) {
      return true;
    } else {
      return false;
    }
  };

  newMenuButtonRule = () => {
    var dateRegex =
      /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/; //dd.MM.yyyy

    if (
      !(isNaN(this.state.startDateNew) || this.state.startDateNew === null) &&
      !(isNaN(this.state.endDateNew) || this.state.endDateNew === null)
    ) {
      let startDate = format(this.state.startDateNew, "dd.MM.yyyy", {
        locale: de,
      }).toString();
      let endDate = format(this.state.endDateNew, "dd.MM.yyyy", {
        locale: de,
      }).toString();

      if (isBefore(this.state.endDateNew, this.state.startDateNew)) {
        return true;
      } else if (!dateRegex.test(startDate)) {
        return true;
      } else if (!dateRegex.test(endDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  render() {
    const { classes } = this.props;
    const { user } = this.props.auth0;
    /*  const { name, picture, email } = user; */
    const {
      isWeekly,
      startDateNew,
      endDateNew,
      createNewPeriodDialogOpen,
      featuredListingDialogOpen,
      startFocus,
      endFocus,
      loading,
      openingTime,
      voted,
    } = this.state;

    const dateNow = Date.now();
    const currentWeekStart = format(
      startOfWeek(dateNow, { weekStartsOn: 1 }),
      "dd.MM.",
      { locale: de }
    ).toString();
    const currentWeekEnd = format(
      endOfWeek(dateNow, { weekStartsOn: 1 }),
      "dd.MM.",
      { locale: de }
    ).toString();
    const nextWeekStart = format(nextMonday(dateNow), "dd.MM.", {
      locale: de,
    }).toString();
    const nextWeekEnd = format(
      nextSunday(endOfWeek(dateNow, { weekStartsOn: 1 })),
      "dd.MM.",
      { locale: de }
    ).toString();

    return (
      <div>
        {loading ? (
          <Box>
            <Box sx={{ width: 200, marginRight: 0, my: 10, mx: 5 }}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
            <Box sx={{ width: 200, marginRight: 0, my: -30, mx: 35 }}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          </Box>
        ) : (
          <Grid container className={classes.root} spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container alignContent="flex-start" spacing={3}>
                <Grid item>
                  <Button
                    className={classes.backButton}
                    startIcon={<ArrowBackIosSharpIcon />}
                    component={NavLink}
                    to="/"
                  >
                    Zurück
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <h3 className={classes.labelAlignment}>Mittagsmenü</h3>

              <Grid
                container
                alignContent="flex-start"
                className={classes.tilesAlignment}
                spacing={0}
              >
                {!isWeekly ? (
                  <Grid
                    container
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    spacing={3}
                    className={classes.tilesGroupAlignment}
                  >

                    <Can
                      user={user}
                      perform="UploadMenu"
                      yes={() => (
                        <Grid key={0} item>
                          <Paper className={classes.paperDisabled} square>
                            <Grid
                              container
                              wrap="wrap"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography className={classes.control}>
                                  <b>Aktuelle Woche pflegen</b>
                                </Typography>
                                <br />
                                <br />
                                <Typography className={classes.controlDate}>
                                  {currentWeekStart} - {currentWeekEnd}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      )}
                      no={() => (
                        <Grid key={0} item>
                          <Paper className={classes.paper} elevation={3} square>
                            <Grid
                              container
                              wrap="wrap"
                              spacing={2}
                              component={NavLink}
                              className={classes.paperLink}
                              to="/currentweek"
                            >
                              <Grid item>
                                <Typography className={classes.control}>
                                  <b>Aktuelle Woche pflegen</b>
                                </Typography>
                                <br />
                                <br />
                                <Typography className={classes.controlDate}>
                                  {currentWeekStart} - {currentWeekEnd}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      )}
                    />
                    <Can
                      user={user}
                      perform="UploadMenu"
                      yes={() => (
                        <Grid key={1} item>
                          <Paper className={classes.paperDisabled} square>
                            <Grid
                              container
                              wrap="wrap"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography className={classes.control}>
                                  <b>Kommende Woche pflegen</b>
                                </Typography>
                                <br />
                                <br />
                                <Typography className={classes.controlDate}>
                                  {nextWeekStart} - {nextWeekEnd}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      )}
                      no={() => (

                        <Grid key={1} item>
                          <Paper className={classes.paper} elevation={3} square>
                            <Grid
                              container
                              wrap="wrap"
                              spacing={2}
                              component={NavLink}
                              className={classes.paperLink}
                              to="/nextweek"
                            >
                              <Grid item>
                                <Typography className={classes.control}>
                                  <b>Kommende Woche pflegen</b>
                                </Typography>
                                <br />
                                <br />
                                <Typography className={classes.controlDate}>
                                  {nextWeekStart} - {nextWeekEnd}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      )}
                    />


                    <Can
                      user={user}
                      perform="UploadMenu"
                      yes={() => (
                        <Grid item xs={6} sm={6} md={6} lg={7} xl={7}>
                          <br />
                          <br />
                          <Typography>
                            Aktuelles Mittagsmenü hochladen <b>(PDF Datei)</b>
                          </Typography>

                          <br />

                          <UploadFiles />
                        </Grid>
                      )}
                      no={() => (
                        <div> </div>
                      )}
                    />
                    <Can
                      user={user}
                      perform="TipPaid"
                      yes={() => (
                        <div></div>
                      )}
                      no={() => (
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <br />
                          <br />
                          <Typography ><b>Wie du mamfito unterstützen kannst?</b></Typography>
                          <br />
                          <ColorButton
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            margin={3}
                            component={NavLink} to="/tip"
                          >
                            mehr erfahren
                          </ColorButton>
                        </Grid>
                      )}
                    />

                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    spacing={3}
                    className={classes.tilesGroupAlignment}
                  >
                    <Can
                      user={user}
                      perform="UploadMenu"
                      yes={() => (
                        <Grid key={2} item>
                          <Paper className={classes.paperDisabled} square>
                            <Grid
                              container
                              wrap="wrap"
                              spacing={2}

                            >
                              <Grid item>
                                <Typography className={classes.control}>
                                  <b>Aktuelles Mittagsmenü bearbeiten</b>
                                </Typography>
                                <br />
                                <br />
                                <br />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>


                      )}
                      no={() => (

                        <Grid key={2} item>
                          <Paper className={classes.paper} elevation={3} square>
                            <Grid
                              container
                              wrap="wrap"
                              spacing={2}
                              component={NavLink}
                              className={classes.paperLink}
                              to="/longervalid"
                            >
                              <Grid item>
                                <Typography className={classes.control}>
                                  <b>Aktuelles Mittagsmenü bearbeiten</b>
                                </Typography>
                                <br />
                                <br />
                                <br />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>

                      )}
                    />

                    <Can
                      user={user}
                      perform="UploadMenu"
                      yes={() => (

                        <Grid key={3} item>
                          <Paper className={classes.paperDisabled} square>
                            <Grid
                              container
                              wrap="wrap"
                              spacing={2}

                            >
                              <Grid item>
                                <Typography className={classes.control}>
                                  <b>
                                    Neues <br /> Mittagsmenü <br /> anlegen
                                  </b>
                                </Typography>
                                <br />
                                <br />
                                <br />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>

                      )}
                      no={() => (

                        <Grid key={3} item>
                          <Paper className={classes.paper} elevation={3} square>
                            <Grid
                              container
                              wrap="wrap"
                              spacing={2}
                              className={classes.paperLink}
                              onClick={() => this.createNewPeriodDialog()}
                            >
                              <Grid item>
                                <Typography className={classes.control}>
                                  <b>
                                    Neues <br /> Mittagsmenü <br /> anlegen
                                  </b>
                                </Typography>
                                <br />
                                <br />
                                <br />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>

                      )}
                    />

                    <Can
                      user={user}
                      perform="UploadMenu"
                      yes={() => (
                        <Grid item xs={6} sm={6} md={6} lg={7} xl={7}>
                          <br />
                          <br />
                          <Typography>
                            Aktuelles Mittagsmenü hochladen <b>(PDF Datei)</b>
                          </Typography>

                          <br />

                          <UploadFiles />
                        </Grid>
                      )}
                      no={() => (
                        <div> </div>
                      )}
                    />
                    <Can
                      user={user}
                      perform="TipPaid"
                      yes={() => (
                        <div></div>
                      )}
                      no={() => (
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <br />
                          <br />
                          <Typography ><b>Wie du mamfito unterstützen kannst?</b></Typography>
                          <br />
                          <ColorButton
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            margin={3}
                            component={NavLink} to="/tip"
                          >
                            mehr erfahren
                          </ColorButton>
                        </Grid>
                      )}
                    />
                  </Grid>
                )}

                <Grid
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  key={3}
                  item
                  className={classes.formBox}
                >
                  <h3> Unseren Mittagstisch gibt es an folgenden Tagen</h3>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 400 }}
                      aria-label="simple table"
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Tag</TableCell>
                          <TableCell align="left">von</TableCell>
                          <TableCell align="left">bis</TableCell>
                          <TableCell align="left">Mittagstisch</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {openingTime.map((row) => (
                          <TableRow
                            key={row.day}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              align="left"
                              padding="normal"
                              component="th"
                              scope="row"
                            >
                              {row.dayLabel}
                            </TableCell>
                            {row.noLunch ? (
                              <TableCell align="left" padding="normal">
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  adapterLocale={de}
                                >
                                  <TimePicker
                                    value={row.timeFrom}
                                    onChange={(e) =>
                                      this.handleTimeChangeFrom(e, row.day)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className={classes.timePicker}
                                        variant="standard"
                                        size="small"
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </TableCell>
                            ) : (
                              <TableCell
                                align="center"
                                padding="normal"
                                colSpan={2}
                              >
                                kein Mittagstisch
                              </TableCell>
                            )}
                            {row.noLunch ? (
                              <TableCell align="left" padding="normal">
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  adapterLocale={de}
                                >
                                  <TimePicker
                                    value={row.timeTo}
                                    disableOpenPicker={false}
                                    onChange={(e) =>
                                      this.handleTimeChangeTo(e, row.day)
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className={classes.timePicker}
                                        variant="standard"
                                        size="small"
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </TableCell>
                            ) : null}
                            <TableCell align="left" padding="normal">
                              <Switch
                                checked={row.noLunch}
                                onChange={(e) =>
                                  this.handleIsClosed(e, row.day)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Dialog
          open={createNewPeriodDialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Fragment>
            <DialogTitle id="alert-dialog-title">
              Neues Menu erstellen
            </DialogTitle>
            <DialogContent>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                <DatePicker
                  mask={"__.__.____"}
                  label="Gültig von"
                  value={startDateNew}
                  onChange={(newValue) =>
                    this.handleStartDateNewChange(newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      className={classes.periodeDate}
                      {...params}
                      required
                      onFocus={() => this.handleStartDateTouch()}
                      helperText={this.handleDateHelperText(startFocus, params)}
                      error={this.handleDateError(startFocus, params)}
                    />
                  )}
                />
                <span className={classes.dataPickerSpace} />
                <DatePicker
                  mask={"__.__.____"}
                  label="bis"
                  value={endDateNew}
                  onChange={(newValue) => this.handleEndDateNewChange(newValue)}
                  renderInput={(params) => (
                    <TextField
                      required
                      className={classes.periodeDate}
                      {...params}
                      onFocus={() => this.handleEndDateTouch()}
                      helperText={this.handleDateHelperText(endFocus, params)}
                      error={this.handleDateError(endFocus, params)}
                    />
                  )}
                />
              </LocalizationProvider>

              <br />
              <br />

              <DialogContentText id="alert-dialog-description">
                Die aktuellen Einträge werden überschrieben
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.createNewPeriod(this.state)}
                color="primary"
                disabled={this.newMenuButtonRule()}
                autoFocus
              >
                Erstellen
              </Button>
              <Button onClick={this.handleDialogCloseNewDate} color="secondary">
                Abbrechen
              </Button>
            </DialogActions>
          </Fragment>
        </Dialog>

        <Dialog
          open={featuredListingDialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {voted ? (
            <Fragment>
              <DialogTitle id="alert-dialog-title1">
                {"Danke für dein Feedback!"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description1">
                  Wir bauen dann mal weiter! Hab einen tollen Tag!
                </DialogContentText>
                {/* <img style={{ margin: "auto", display: "block" }} height={100} src={smilie} alt="Danke :)" /> */}
                <span
                  style={{
                    fontSize: 70,
                    textAlign: "center",
                    display: "block",
                  }}
                >
                  &#128522;
                </span>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleDialogClose}
                  color="primary"
                  autoFocus
                >
                  Schließen
                </Button>
              </DialogActions>
            </Fragment>
          ) : (
            <Fragment>
              <DialogTitle id="alert-dialog-title">
                In den Suchergebnissen ganz oben auftauchen <br /> Das bringt
                mehr Sichtbarkeit für dein Menü
              </DialogTitle>
              <DialogContent>
                <CardMedia
                  component="img"
                  height="400"
                  image={FeaturedListingImage}
                  alt="green iguana"
                />
                <DialogContentText id="alert-dialog-description">
                  <h4>
                    {" "}
                    Würdest du das für 3,99€ für eine Woche freischalten?
                  </h4>
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActionButton}>
                <Button onClick={() => this.onSurvey("ja")} color="primary">
                  Ja
                </Button>
                <Button onClick={() => this.onSurvey("nein")} color="secondary">
                  Nein
                </Button>
                <Button onClick={() => this.onSurvey("teuer")} color="primary">
                  Ist mir zu teuer
                </Button>
              </DialogActions>
            </Fragment>
          )}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(useStyles)(withAuth0(withRouter(LunchContent)));
