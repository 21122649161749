import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Popover, Typography, Box, Card, CardActions, CardContent } from '@mui/material';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import Can from "../auth/Can";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  buttonLast: {
    marginRight: theme.spacing(5),
  }
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#fff",
    '&:hover': {
      backgroundColor: "#fff",
    },
  },
}))(Button);



export default function LogoutButton() {
  const classes = useStyles();
  const { logout, user } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (

    <div className={classes.root}>
      <ColorButton
        variant="contained"
        color="default"
        size="small"
        component={NavLink} to="/"
        className={classes.button}
        startIcon={<HomeOutlinedIcon />}
      >
        Startseite
      </ColorButton>

      <Can
        user={user}
        perform="TipPaid"
        yes={() => (
          <ColorButton
            variant="contained"
            color="default"
            size="small"
            onClick={handleClick}
            className={classes.button}
            startIcon={<LoyaltyIcon />}
          >
             Deine Unterstützung
          </ColorButton>

        )}
        no={() => (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            component={NavLink} to="/tip"
         
            startIcon={<LoyaltyIcon />}
          >
            Deine Unterstützung
          </Button>

        )}

      />


      <ColorButton
        variant="contained"
        color="default"
        size="small"
        component={NavLink} to="/support"
        className={classes.button}
        startIcon={<SupportAgentOutlinedIcon />}
      >
        Support
      </ColorButton>


      <ColorButton variant="contained" className={classes.buttonLast} size="small" startIcon={<ExitToAppOutlinedIcon />} onClick={() => logout({
        returnTo: window.location.origin,
      })}>Abmelden</ColorButton>


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 132,
              height: 132,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}

      >

    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
      <CardContent>
      
      <Typography variant="h5" component="div">
       Du bist spitze!
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        
      </Typography>
      <Typography variant="body2">
       Danke, dass Du mamfito mit deinem Trinkgeld unterstützt.
        <br />
        <br />
        <span style={{ fontSize: 40, textAlign: "left", display: "block" }}>&#128522;</span>
      </Typography>
    </CardContent>
    <CardActions>
      <Button variant="outlined" color="primary" size="small" href="https://billing.stripe.com/p/login/cN23eEeaz66g95m8ww" target="_blank" rel="noopener" >Dein Trinkgeld anpassen</Button>
    </CardActions>
      </Card>
    </Box>
      </Popover>

    </div>

  );
};