import React from "react";
import { SocialIcon } from 'react-social-icons';

class Footer extends React.Component {
  render() {
    return (
      <footer className="bg-light p-3 text-center"  style={{ marginTop: 130, display: "block", textAlign:"center" }}>
       <SocialIcon bgColor="#b4b4b4" style={{ height:30, width: 30, marginRight: 10 }} url="https://www.instagram.com/mamfito/" target="_blank" />
        <SocialIcon bgColor="#b4b4b4" style={{ height:30, width: 30, marginRight: 10 }} url="https://www.facebook.com/mamfito/" target="_blank" /> 
        <SocialIcon bgColor="#b4b4b4" style={{ height:30, width: 30 }} url="https://www.youtube.com/channel/UCZSiaHJscsk8TwSRN78eIvg" target="_blank" /> 
        <p >
        © Copyright mamfito {new Date().getUTCFullYear()}. All Right Reserved.
        </p>
      </footer>
    );
  }
}
export default Footer;
