import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { format } from 'date-fns';
import { de } from 'date-fns/locale';


const useStyles = makeStyles({
  root: {
    maxWidth: 360,
    marginBottom: "15px"
  }
});

const displayDetails = (props, event) => {
  props.parentCallback(props.index);
  event.preventDefault();
  //return props
};



export default function EventActionCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Anfrage: {props.details.eventType}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Datum: {format(new Date(props.details.date), "dd.MM.yyyy", { locale: de }).toString()}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Personen: {props.details.nrOfPeople}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {/*<IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <Button size="small" color="primary">
          Primary
        </Button>
        <Button size="small" color="secondary">
          Secondary
        </Button>*/}
        <Typography variant="body2" color="textSecondary" component="p">
          Empfangen am: {format(new Date(props.details.createdAt), "dd.MM.yyyy", { locale: de }).toString()}
        </Typography>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Button size="small" color="primary" onClick={(e) => displayDetails(props, e)}>
          Details
        </Button>
      </CardActions>
    </Card>
  );
}