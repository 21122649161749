import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import { NavLink } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import { Typography } from "@material-ui/core";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ListSubheader from '@mui/material/ListSubheader';
import { Link } from '@mui/material';


const useStyles = theme => ({
    supportContainer: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    startEndDate: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(0)

    },
    weekly: {
        transform: "scale(1.3)"
    }
});


class SupportContent extends Component {

    render() {

        const { classes } = this.props;

        return (

            <div className={classes.supportContainer}>
                <br />
                <Button startIcon={<ArrowBackIosSharpIcon />} component={NavLink} to="/" >Zurück</Button>
                <br />     <br />     <br />



                <Typography variant="h6" gutterBottom >Wobei brauchst du Unterstützung? </Typography>
                <Typography variant="subtitle1" gutterBottom>Beschreib uns worum es geht und kontaktiere uns.</Typography>
                <Typography variant="subtitle1" gutterBottom>Wir kümmern uns dann so schnell es geht um dein Anliegen.</Typography>

                <br/><br/>


                <List subheader={<ListSubheader>Wie?</ListSubheader>} sx={{ width: '100%', maxWidth:700, bgcolor: 'background.paper' }} >
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton >
                            <ListItemIcon>
                                <WhatsAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Schick uns eine Whatsapp (gerne auch ein Video/Photo von deinem Problem)" secondary="+49 151 1140555" />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton component={Link} href="mailto:info@mamfito.de">
                            <ListItemIcon>
                                <MailOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="Schreib uns eine E-Mail über" secondary="info@mamfito.de" />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItemButton component={Link} href="tel:+4915111405535">
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <PhoneInTalkIcon />
                            </ListItemIcon>
                            <ListItemText primary="Oder ruf uns an" secondary="+49 151 1140555" />
                        </ListItem>
                    </ListItemButton>
                </List>


            </div >
        );


    }
}

export default withStyles(useStyles)(withAuth0(SupportContent))