import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Grid } from "@material-ui/core";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import { NavLink } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";


const useStyles = theme => ({
    profileContainer: {
        marginLeft: theme.spacing(5),
        maxWidth: theme.spacing(130)
    },
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    startEndDate: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(0)

    },
    weekly: {
        transform: "scale(1.3)"
    },
    backButton: {
        paddingLeft: theme.spacing(6),
        marginTop: theme.spacing(2),
      },
});


class ProfileContent extends Component {

    render() {

        const { classes } = this.props;

        return (
        <div>

        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
            <Grid container alignContent="flex-start" spacing={3}>
              <Grid item>
                <Button className={classes.backButton} startIcon={<ArrowBackIosSharpIcon />} component={NavLink} to="/" >Zurück</Button>
              </Grid>
            </Grid>
          </Grid>

            
        <iframe title="Location Vermieten" allowFullScreen loading="lazy" src="https://mamfito.de/trinkgeld-fuer-mamfito" frameBorder="0" style={{ border: "none", display: "block", height: "80vh", width: "100vw" }}  ></iframe>

        </Grid>
            </div>
        );
      


    }
}

export default withStyles(useStyles)(withAuth0(ProfileContent))