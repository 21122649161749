import React, { Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import { withAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";

const useStyles = theme => ({
  profileContainer: {
    marginLeft: theme.spacing(3)
  }
});

class Forbidden extends React.Component {
  render() {

    return (
      <Fragment>
        <Typography variant="h3" color="textSecondary" component="h3" style={{ textAlign: "center", marginTop: "25vh" }}>
         Forbidden
          </Typography>
          <Typography variant="h6" color="textSecondary" component="h6" style={{ textAlign: "center", marginTop: "2vh" }}>
          You are not allowed to access this page. For more details please contact mamfito support
          </Typography>
      </Fragment>

    );
  }
}

export default withStyles(useStyles)(withAuth0(Forbidden));
