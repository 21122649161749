import React, { useEffect, useState } from "react";
import { useParams, withRouter, NavLink, useHistory } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EventService from "../services/event.service";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import EditIcon from '@material-ui/icons/Edit';
import PublicIcon from '@material-ui/icons/Public';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PreviewIcon from '@mui/icons-material/Visibility';
import { Grid, CircularProgress, Typography, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Autocomplete, FormControlLabel, Checkbox, Paper, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { de } from "date-fns/locale";
import { isValid, isAfter } from 'date-fns';
import Can from "../auth/Can";
import ReactQuill from 'react-quill';
import validator from 'validator'
import 'react-quill/dist/quill.snow.css';
import '../css/quill-custom.css';


const HtmlRenderedTextField = ({ htmlValue }) => {
  return (
    <div
      className={'ql-editor'}
      dangerouslySetInnerHTML={{ __html: htmlValue }}
      style={{
        border: '1px solid #dcdcdc',
        //lineHeight: '0.7',
        borderRadius: '4px',
        minHeight: '56px', // Adjust as needed
      }}
    />
  );
};



const useStyles = makeStyles(() => ({
  mainContainer: {
    marginLeft: "40px",
    marginRight: "40px",
    marginTop: "24px",
  },
  root: {
    flexGrow: 1,
  },

  button: {
    marginRight: "10px !important"
  },

  paper: {
    padding: 10
  },

  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },

  progress: {
    color: "#000"
  },

  imageUploadButton: {
    color: "#d32f2f",
    fontSize: "0.75rem",
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "14px",
    marginBottom: 0,
    marginLeft: "14px"
  },

  customErrorLabel: {
    color: "#d32f2f",
    fontSize: "0.75rem",
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "14px",
    marginBottom: 0,
    marginLeft: "14px"
  },

  editorContainer: {
    position: 'relative',
  },
  placeholder: {
    position: 'absolute',
    top: '50px',
    left: '15px',
    pointerEvents: 'none',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontWeight: 40,
    fontSize: '1rem',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    color: "rgba(0, 0, 0, 0.57)",
  },
  placeholderHidden: {
    display: 'none'
  },

  errorBorder: {
    border: '1px solid #d32f2f',
    borderRadius: '5px',
  },
}));

const iconCheckBox = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIconCheckBox = <CheckBoxIcon fontSize="small" />;

// Array of required field names
const requiredFields = ['title', 'description', 'start_date', 'end_date', 'venue', 'organizer', 'category', 'tags', 'imageFile'];
const quillToolbar = {
  toolbar: [
    [{ 'header': [3, 4, 5, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    ['link'],
    ['clean']
  ],
};


const EventDetails = ({ auth0 }) => {
  const { id } = useParams();
  const classes = useStyles();
  const [event, setEvent] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editedEvent, setEditedEvent] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Add the loading state
  const [venueOptions, setVenueOptions] = useState([]);
  const [organizerOptions, setOrganizerOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [isWholeDayEvent, setIsWholeDayEvent] = useState(event?.data?.all_day || false);
  const [image, setImage] = useState(null);

  const { user } = auth0;

  // State to hold form validation errors
  const [formErrors, setFormErrors] = useState({});
  const [editorFocused, setEditorFocused] = React.useState(true);

  const history = useHistory();

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const token = await getToken();
        const eventData = await EventService.getEventDetails(id, token);
        setEvent(eventData);
        setImage(eventData.data.image);
        setIsWholeDayEvent(eventData.data.all_day);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          history.push("/forbidden");
        } else {
          console.error("Error fetching event details", error);
        }
      }
    };

    const fetchVenueOptions = async () => {
      try {
        const token = await getToken();
        const venuesData = await EventService.getVenues(token);
        if (Array.isArray(venuesData.data)) {
          setVenueOptions(venuesData.data);
        } else {
          console.error('Venue options data is not in the correct format');
        }
      } catch (error) {
        console.error('Error fetching venue options', error);
      }
    };

    const fetchOrganizerOptions = async () => {
      try {
        const token = await getToken();
        const organizersData = await EventService.getOrganizers(token);
        if (Array.isArray(organizersData.data)) {
          setOrganizerOptions(organizersData.data);
        } else {
          console.error('Organizer options data is not in the correct format');
        }
      } catch (error) {
        console.error('Error fetching organizer options', error);
      }
    };

    const fetchTagOptions = async () => {
      try {
        const token = await getToken();
        const tagsData = await EventService.getTags(token);
        if (Array.isArray(tagsData.data)) {
          setTagOptions(tagsData.data);
        } else {
          console.error('Tag options data is not in the correct format');
        }
      } catch (error) {
        console.error('Error fetching tag options', error);
      }
    };

    const fetchCategoryOptions = async () => {
      try {
        const token = await getToken();
        const categoriesData = await EventService.getCategories(token);
        if (Array.isArray(categoriesData.data)) {
          setCategoryOptions(categoriesData.data);
        } else {
          console.error('Category options data is not in the correct format');
        }
      } catch (error) {
        console.error('Error fetching category options', error);
      }
    };

    const getToken = async () => {
      const { getAccessTokenSilently } = auth0;
      const token = await getAccessTokenSilently();
      return token;
    };

    fetchEventDetails();
    fetchVenueOptions();
    fetchOrganizerOptions();
    fetchCategoryOptions();
    fetchTagOptions();
  }, [id, auth0, history]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
  
    // Check if the file size is greater than 3 MB
    if (file && file.size > 3 * 1024 * 1024) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        imageFile: 'Bildgröße darf nicht größer als 3 MB sein. Bitte wähle ein kleineres Bild',
      }));
    } else {
      setImage(file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        imageFile: null, // Clear the error for the image upload when it's filled
      }));
    }
  };

  const handleImageDelete = () => {
    setImage(null);
  };

  const handleDeleteConfirmationOpen = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const handleDeleteEvent = async () => {

    const token = await auth0.getAccessTokenSilently();
    try {

      setLoading(true); // Set the loading state to true
      const deletedEvent = { ...event };
      // Update the event state with the saved data
      await EventService.deleteEvent(deletedEvent.data.id, token);
      setIsEdit(false);
      setLoading(false); // Set the loading state to false after saving
      setIsDeleteConfirmationOpen(false);
      history.push("/events");
    } catch (error) {
      console.error("Error deleting event data", error);
      setLoading(false); // Set the loading state to false in case of an error
      setIsDeleteConfirmationOpen(false);
    }


  };

  const handleEdit = () => {
    setIsEdit(true);
    const startDate = new Date(event.data.start_date);
    const endDate = new Date(event.data.end_date);
    setEditedEvent({
      ...event.data,
      start_date: startDate,
      end_date: endDate,
    });
  };

  const isStartDateGreaterThanEndDate = (startDate, endDate) => {
    return isAfter(startDate, endDate);
  };

  const isValidWebsite = (value) => {

    if (!value) {
      return true;
    }
    if (validator.isURL(value)) {
      return true;
    }

    return false;
  }

  const handleSave = () => {

    const saveEditedEvent = async () => {
      const token = await auth0.getAccessTokenSilently();
      const updatedEvent = { ...event };
      try {

        setLoading(true); // Set the loading state to true

        let formData = new FormData();
        formData.append("file", image);

        updatedEvent.data = editedEvent;


        if (!isValidUrl(image)) {
          const imageUrl = await EventService.uploadEventImage(formData, token);
          updatedEvent.data.image = imageUrl.data.imageUrl;
        }

        //const imageUrl = !isValidUrl(image) ? await EventService.uploadEventImage(formData, token) : null; //tbd
        //updatedEvent.data.image = imageUrl ? imageUrl.data.imageUrl : ""; //tbd
        updatedEvent.data.start_date = updatedEvent.data.start_date.toISOString();
        updatedEvent.data.end_date = updatedEvent.data.end_date.toISOString();


        console.log("Event data saved:", updatedEvent);

        // Update the event state with the saved data
        await EventService.updateEvent(updatedEvent.data.id, updatedEvent, token);
        setEvent(updatedEvent);
        setIsEdit(false);
        setLoading(false); // Set the loading state to false after saving
      } catch (error) {
        console.error("Error saving event data", error);
        setLoading(false); // Set the loading state to false in case of an error

      }
    };

    const hasErrors = requiredFields.some((fieldName) => {
      if (fieldName === 'start_date' || fieldName === 'end_date') {
        return !editedEvent[fieldName];
      }
      if (fieldName === 'venue' || fieldName === 'organizer' || fieldName === 'category') {
        return !editedEvent[fieldName]?.id;
      }
      if (fieldName === 'tags') {
        return editedEvent[fieldName].length === 0;
      }
      return typeof editedEvent[fieldName] === 'string' && !editedEvent[fieldName].trim();
    });


    if (hasErrors || (editedEvent.start_date && editedEvent.end_date && isStartDateGreaterThanEndDate(editedEvent.start_date, editedEvent.end_date)) || !isValid(editedEvent.start_date) || !isValid(editedEvent.end_date) || !image || isContentEmpty(editedEvent.description) || !isValidWebsite(editedEvent.website)) {
      const newErrors = {};
      requiredFields.forEach((fieldName) => {
        if (fieldName === 'start_date' && !editedEvent[fieldName]) {
          newErrors[fieldName] = 'Wann geht das Event los?';
        } else if (fieldName === 'start_date' && !isValid(editedEvent.start_date)) {
          newErrors[fieldName] = 'Bitte ein gültiges Datum eintragen';
        } else if (fieldName === 'end_date' && !editedEvent[fieldName]) {
          newErrors[fieldName] = 'Wann ist das Event ungefähr zu Ende?';
        } else if (fieldName === 'end_date' && !isValid(editedEvent.end_date)) {
          newErrors[fieldName] = 'Bitte ein gültiges Datum eintragen';
        } else if (fieldName === 'title' && !editedEvent[fieldName]) {
          newErrors[fieldName] = 'Was ist der Titel für dein Event?';
        } else if (fieldName === 'description' && (!editedEvent[fieldName] || editedEvent[fieldName] === '<p><br></p>')) {
          newErrors[fieldName] = 'Beschreibe dein Event, damit interessierte Besucher sehen, was sie erwartet';
        } else if (fieldName === 'venue' && !editedEvent[fieldName]?.id) {
          newErrors[fieldName] = 'Wo findet das Event statt?';
        } else if (fieldName === 'organizer' && !editedEvent[fieldName]?.id) {
          newErrors[fieldName] = 'Wer veranstaltet das Event?';
        } else if (fieldName === 'category' && !editedEvent[fieldName]?.id) {
          newErrors[fieldName] = 'Bitte wähle den Event Typ, der am besten passt.';
        } else if (fieldName === 'tags' && editedEvent[fieldName].length === 0) {
          newErrors[fieldName] = 'Wähle ein paar Schlagworte, die dein Event zusätzlich beschreiben';
        } else if (fieldName === 'imageFile' && !editedEvent[fieldName] && !image) {
          newErrors[fieldName] = 'Bitte ein Bild zum Event einfügen';
        }
      });

      // Perform additional error checks for start_date and end_date
      if (editedEvent.start_date && editedEvent.end_date && isStartDateGreaterThanEndDate(editedEvent.start_date, editedEvent.end_date)) {
        newErrors.start_date = 'Der Start muss vor dem Ende sein';
        newErrors.end_date = 'Das Ende muss nach dem Start sein';
      }

      if (!isValidWebsite(editedEvent.website)) {
        newErrors.website = 'Der eingegebene Link ist ungültig';
      }


      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return;
    }


    saveEditedEvent();
  };




  const handleCancel = () => {
    setIsEdit(false);

    // Clear the error for the corresponding field when it's filled
    setFormErrors((prevErrors) => {
      const clearedErrors = { ...prevErrors };
      Object.keys(clearedErrors).forEach((fieldName) => {
        clearedErrors[fieldName] = null;
      });
      return clearedErrors;
    });

  };

  const handleInputChangeDetails = (value) => {


    setEditedEvent((prevState) => ({
      ...prevState,
      description: value,
    }));

    // Clear the error for the corresponding field when it's filled
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      description: null,
    }));

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEditedEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear the error for the corresponding field when it's filled
    /* setFormErrors((prevErrors) => ({
       ...prevErrors,
       [name]: null,
     }));*/

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: null,
      start_date: (name === 'start_date' || name === 'end_date') ? null : prevErrors.start_date,
      end_date: (name === 'start_date' || name === 'end_date') ? null : prevErrors.end_date,
    }));


  };

  /*const handleBlur = (e) => {
    const { name, value } = e.target;
    if (requiredFields.includes(name) && !value.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'This field is required',
      }));
    }
  };*/


  const handleWholeDayEventChange = (e) => {
    setIsWholeDayEvent(e.target.checked);
  };

  const handleEditorFocus = () => {
    setEditorFocused(false);
  };

  const handleEditorBlur = () => {
    setEditorFocused(true);
  };

  const isContentEmpty = (content) => {
    const strippedContent = content.replace(/<[^>]*>/g, ''); // Remove HTML tags
    return strippedContent.trim() === ''; // Check if the stripped content is empty
  };



  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };


  if (!event) {
    return <div className={classes.overlay}>
      <CircularProgress className={classes.progress} />
    </div>;
  }

  return (

    <Can
      user={user}
      perform="EventManager"
      no={() => {
        history.push("/forbidden")
        return null
      }}
      yes={() => (
        <div className={classes.mainContainer}>

          {loading && (
            <div className={classes.overlay}>
              <CircularProgress className={classes.progress} />
            </div>
          )}

          <Grid container className={classes.root} spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                className={classes.backButton}
                startIcon={<ArrowBackIosSharpIcon />}
                component={NavLink}
                to="/events"
              >
                Zurück
              </Button>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              {!isEdit && (
                <>
                  <Button
                    variant="contained"
                    color="info"
                    className={classes.button}
                    href={event.data.url}
                    target="_blank"
                    endIcon={<PreviewIcon />}
                  >
                    Anzeigen
                  </Button>
                  <Button
                    variant="contained"
                    color="inherit"
                    className={classes.button}
                    onClick={handleEdit}
                    endIcon={<EditIcon />}
                  >
                    Bearbeiten
                  </Button>
                </>
              )}
              {isEdit && (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    className={classes.button}
                    onClick={handleSave}
                    endIcon={<PublicIcon />}
                  >
                    Speichern
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    className={classes.button}
                    onClick={handleCancel}
                  >
                    Abbrechen
                  </Button>
                </>
              )}
              <Button
                variant="contained"
                color="inherit"
                onClick={handleDeleteConfirmationOpen}
                endIcon={<DeleteIcon />}
              >
                Löschen
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Titel"
                value={isEdit ? editedEvent.title : event.data.title}
                fullWidth
                required
                //InputLabelProps={{ shrink: true }}
                variant="outlined"
                disabled={!isEdit}
                name="title"
                onChange={handleInputChange}
                //onBlur={handleBlur}
                error={Boolean(formErrors.title)}
                helperText={formErrors.title}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                accept="image/*"
                id="image-upload-input"
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
              <label htmlFor="image-upload-input">
                <Button variant="contained" component="span" disabled={!isEdit} endIcon={<AddPhotoAlternateOutlinedIcon />}>
                  Bild zum Event einfügen
                </Button>
                {isEdit && (
                  <span>
                    <br />
                    <Typography variant="caption">* Mit dem Upload des Bildes bestätigst du, dass du die Rechte hast, das Bild zu verwenden</Typography>
                  </span>
                )
                }
              </label>
              {formErrors.imageFile && (
                <p className={classes.imageUploadButton}>{formErrors.imageFile}</p>
              )}
            </Grid>

            {image && (
              <Grid item xs={6} xl={3} >
                <Paper className={classes.paper} elevation={3}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="h6">Eventbild</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton className={classes.deleteButton} onClick={handleImageDelete} disabled={!isEdit}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <img src={isValidUrl(image) ? image : URL.createObjectURL(image)} alt="Eventbild" style={{ width: "100%", marginTop: "10px" }} />
                </Paper>
              </Grid>
            )}
            <Grid item xs={12}>
              {!isEdit ?
                <HtmlRenderedTextField
                  htmlValue={isEdit ? editedEvent.description : event.data.description}
                />
                :
                <div className={classes.editorContainer}>
                  <label htmlFor="editor" className={(isContentEmpty(isEdit ? editedEvent.description : event.data.description) && editorFocused) ? classes.placeholder : classes.placeholderHidden}>
                    Beschreibe dein Event &#42;
                  </label>
                  <ReactQuill id="editor" disabled={!isEdit} modules={quillToolbar} className={Boolean(formErrors.description) ? classes.errorBorder : ''}
                    theme="snow"
                    value={editedEvent.description}
                    onChange={handleInputChangeDetails}
                    onFocus={handleEditorFocus}
                    onBlur={handleEditorBlur} />
                  {formErrors.description && (
                    <p className={classes.customErrorLabel}>{formErrors.description}</p>
                  )}
                </div>
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                {isWholeDayEvent ? (
                  <DatePicker
                    label="Event Start"
                    value={isEdit ? editedEvent.start_date : event.data.start_date}
                    onChange={(date) =>
                      handleInputChange({
                        target: { name: "start_date", value: date },
                      })
                    }
                    fullWidth
                    disabled={!isEdit}
                    renderInput={(params) => (
                      <TextField {...params} required helperText={formErrors.start_date} error={Boolean(formErrors.start_date)} label="Start Date" variant="outlined" fullWidth />
                    )}
                  />
                ) : (
                  <DateTimePicker
                    label="Event Start"
                    value={isEdit ? editedEvent.start_date : event.data.start_date}
                    onChange={(date) =>
                      handleInputChange({
                        target: { name: "start_date", value: date },
                      })
                    }
                    fullWidth
                    disabled={!isEdit}
                    ampm={false}
                    renderInput={(params) => (
                      <TextField {...params} required helperText={formErrors.start_date} error={Boolean(formErrors.start_date)} label="Start Date" variant="outlined" fullWidth />
                    )}
                  />
                )}
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                {isWholeDayEvent ? (
                  <DatePicker
                    label="Event Ende"
                    value={isEdit ? editedEvent.end_date : event.data.end_date}
                    onChange={(date) =>
                      handleInputChange({
                        target: { name: "end_date", value: date },
                      })
                    }
                    fullWidth
                    disabled={!isEdit}
                    renderInput={(params) => (
                      <TextField {...params} required helperText={formErrors.end_date} error={Boolean(formErrors.end_date)} label="End Date" variant="outlined" fullWidth />
                    )}
                  />
                ) : (
                  <DateTimePicker
                    label="Event Ende"
                    value={isEdit ? editedEvent.end_date : event.data.end_date}
                    onChange={(date) =>
                      handleInputChange({
                        target: { name: "end_date", value: date },
                      })
                    }
                    fullWidth
                    disabled={!isEdit}
                    ampm={false}
                    renderInput={(params) => (
                      <TextField {...params} required helperText={formErrors.end_date} error={Boolean(formErrors.end_date)} label="End Date" variant="outlined" fullWidth />
                    )}
                  />
                )}
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isWholeDayEvent}
                    onChange={handleWholeDayEventChange}
                    disabled={!isEdit}
                  />
                }
                label="Ganztägiges Event"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="venue-autocomplete"
                options={venueOptions}
                getOptionLabel={(option) => option?.venue || ""}
                disabled={!isEdit}
                value={isEdit ? editedEvent?.venue : event?.data?.venue || ""}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: {
                      name: "venue",
                      value: newValue ? newValue : null,
                    },
                  });
                }}
                isOptionEqualToValue={(option, value) => option?.venue === value?.venue}
                renderInput={(params) => (
                  <TextField {...params} required helperText={formErrors.venue} error={Boolean(formErrors.venue)} label="Veranstaltungsort" variant="outlined" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="organizer-autocomplete"
                options={organizerOptions}
                getOptionLabel={(option) => option?.organizer || ""}
                disabled={!isEdit}
                value={isEdit ? editedEvent?.organizer : event?.data?.organizer || ""}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: {
                      name: "organizer",
                      value: newValue ? newValue : null,
                    },
                  });
                }}
                isOptionEqualToValue={(option, value) => option?.organizer === value?.organizer}
                renderInput={(params) => (
                  <TextField {...params} required helperText={formErrors.organizer} error={Boolean(formErrors.organizer)} label="Veranstalter" variant="outlined" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="category-autocomplete"
                disabled={!isEdit}
                value={isEdit ? editedEvent?.category : event?.data?.category || ""}
                options={categoryOptions}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: {
                      name: "category",
                      value: newValue ? newValue : null,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} required helperText={formErrors.category} error={Boolean(formErrors.category)} label="Event Typ / Kategorie" variant="outlined" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="tag-autocomplete"
                multiple
                disableCloseOnSelect
                disabled={!isEdit}
                value={isEdit ? editedEvent?.tags : event?.data?.tags || ""}
                options={tagOptions?.sort((a, b) => a.name.localeCompare(b.name))}
                getOptionLabel={(option) => option?.name || ""}
                renderOption={(props, option, { inputValue, selected }) => {
                  const matches = match(option.name, inputValue);
                  const parts = parse(option.name, matches);

                  return (
                    <li {...props}>
                      <Checkbox
                        icon={iconCheckBox}
                        checkedIcon={checkedIconCheckBox}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: {
                      name: "tags",
                      value: newValue ? newValue : [],
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} required helperText={formErrors.tags} error={Boolean(formErrors.tags)} label="Welche Schlagworte beschreiben dein Event?" variant="outlined" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Link zu weiteren Informationen (Optional)"
                value={isEdit ? editedEvent.website : event.data.website}
                fullWidth
                disabled={!isEdit}
                variant="outlined"
                name="website"
                placeholder="https://example.com"
                onChange={handleInputChange}
                inputProps={{ inputMode: 'url', pattern: 'https://.*' }}
                //onBlur={handleBlur}
                error={Boolean(formErrors.website)}
                helperText={formErrors.website}
              />
            </Grid>


          </Grid>
          <Dialog
            open={isDeleteConfirmationOpen}
            onClose={handleDeleteConfirmationClose}
          >
            <DialogTitle>Event löschen?</DialogTitle>
            <DialogContent>
              Willst du das Event wirklich löschen?
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteConfirmationClose} color="primary">
                Ab­bre­chen
              </Button>
              <Button onClick={handleDeleteEvent} color="primary">
                Löschen
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    />


  );
};

export default withAuth0(withRouter(EventDetails));
