import http from "../http-common";

class MenuDataService {
  getAll = async (token) => {
    return http.get("/restaurant", { headers: { "Authorization": `Bearer ${token}` } });
  }

  getAllNext = async (token) => {
    return http.get("/restaurant/nextweek", { headers: { "Authorization": `Bearer ${token}` } });
  }

  getLongerValidMenu = async (token) => {
    return http.get("/restaurant/longervalid", { headers: { "Authorization": `Bearer ${token}` } });
  }

  createNewPeriode = async (startDate, endDate, token) => {
    return http.post("/restaurant/newperiode", [startDate, endDate], { headers: { "Authorization": `Bearer ${token}` } });
  }

  createNewPeriodeWithItems = async (data, token) => {
    return http.post("/restaurant/newperiode/items", data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  getBusinessHours = async (token) => {
    return http.get(`/restaurant/businesshours`, { headers: { "Authorization": `Bearer ${token}` } });
  }

  updateBusinessHours = async (data, token) => {
    return http.post(`/restaurant/businesshours`, data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  submitSurvey = async (data, token) => {
    return http.post(`/restaurant/survey`, data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  getRestaurantProfile = async (token) => {
    return http.get(`/restaurant/profile`, { headers: { "Authorization": `Bearer ${token}` } });
  }

  updateRestaurantProfile = async (data, token) => {
    return http.put(`/restaurant/profile`, data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  updateVacation = async (data, token) => {
    return http.put(`/restaurant/vacation`, data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  get = async (id, token) => {
    return http.get(`/restaurant/${id}`, { headers: { "Authorization": `Bearer ${token}` } });
  }

  createNewRestaurantCW = async (token) => {
    return http.get("/restaurant/cw", { headers: { "Authorization": `Bearer ${token}` } });
  }

  createNewRestaurantNW = async (token) => {
    return http.get("/restaurant/nw", { headers: { "Authorization": `Bearer ${token}` } });
  }

  create(id, data, token) {
    return http.post(`/restaurant/${id}/items`, data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  copyLastWeek(week, data, token) {
    return http.post(`/restaurant/copyLastWeek/${week}`, data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  isLastWeekMaintained(week, data, token) {
    return http.post(`/restaurant/isLastWeekMaintained/${week}`, data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  update(id, data, token) {
    return http.put(`/restaurant/${id}`, data, { headers: { "Authorization": `Bearer ${token}` } });
  }

  deleteMenuItem = async (id, token) => {
    return http.delete(`/items/${id}`, { headers: { "Authorization": `Bearer ${token}` } });
  }

  delete(id, token) {
    return http.delete(`/restaurant/${id}`, { headers: { "Authorization": `Bearer ${token}` } });
  }

  deleteAll(token) {
    return http.delete(`/restaurant`, { headers: { "Authorization": `Bearer ${token}` } });
  }

  findByTitle(title, token) {
    return http.get(`/restaurantv?title=${title}`, { headers: { "Authorization": `Bearer ${token}` } });
  }
}

export default new MenuDataService();