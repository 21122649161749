import React, { Component } from "react";
import UploadService from "../services/upload-files.service";
import { withStyles } from '@mui/styles';
import { withAuth0 } from "@auth0/auth0-react";
import { Box, Typography, Button, ListItem, LinearProgress } from '@mui/material';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

class UploadFiles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            isError: false,
            fileInfos: [],
        };
    }

    componentDidMount() {
       /* UploadService.getFiles().then((response) => {
            this.setState({
                fileInfos: response.data,
            });
        });*/
    }
    
    selectFile(event) {
        this.setState({
            selectedFiles: event.target.files,
        });

    }

    async getToken() {
        const { getAccessTokenSilently } = this.props.auth0;
        let token = await getAccessTokenSilently();
        return token;
      }
    

    async upload() {
        let token = await this.getToken();
        let currentFile = this.state.selectedFiles[0];
        this.setState({
            progress: 0,
            currentFile: currentFile,
        });
        UploadService.upload(token, currentFile, (event) => {
            this.setState({
                progress: Math.round((100 * event.loaded) / event.total),
            });
        })
            .then((response) => {
                this.setState({
                    message: response.data.message,
                    isError: false
                });
                //return UploadService.getFiles();
            })
            /*.then((files) => {
                this.setState({
                    fileInfos: files.data,
                });
            })*/
            .catch((e) => {
                this.setState({
                    progress: 0,
                    message: e.message,
                    currentFile: undefined,
                    isError: true
                });
            });
        this.setState({
            selectedFiles: undefined,
        });
    }

    render() {
        const {
            selectedFiles,
            currentFile,
            progress,
            message,
            fileInfos,
            isError
        } = this.state;

        return (
            <div >
                {currentFile && (
                    <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                            <BorderLinearProgress variant="determinate" value={progress} />
                        </Box>
                        <Box minWidth={35}>
                            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                        </Box>
                    </Box>)
                }
                <label htmlFor="btn-upload">
                    <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: 'none' }}
                        type="file"
                        accept=".pdf,.PDF"
                        onChange={e => this.selectFile(e)} />
                    <Button
                        className="btn-choose"
                        variant="outlined"
                        component="span" >
                        Menü auswählen
                    </Button>
                </label>
               
                <Button
                    className="btn-upload"
                    color="primary"
                    variant="contained"
                    component="span"
                    disabled={!selectedFiles}
                    onClick={e => this.upload(e)}>
                    Hochladen
                </Button>
                <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
                    {message}
                </Typography>
                {selectedFiles && selectedFiles.length > 0 && ( <Typography variant="h6" className="list-header">
                    Mittagmenü
                </Typography>)}
                <div className="file-name">
                    {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
                </div>
                <ul className="list-group">
                    {fileInfos &&
                        fileInfos.map((file, index) => (
                            <ListItem
                                divider
                                key={index}>
                                <a href={file.url}>{file.name}</a>
                            </ListItem>
                        ))}
                </ul>
            </div >
        );
}
}

export default withAuth0(UploadFiles)