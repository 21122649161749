import React, {Fragment} from "react";
import MenuContentCW from "../components/menu-content-cw.component"
import { withAuth0 } from "@auth0/auth0-react";

class CurrentWeek extends React.Component {
  render() {
    return (
     <Fragment>
        <MenuContentCW />
      
        </Fragment>
    );
  }
}

export default withAuth0(CurrentWeek);
