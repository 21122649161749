import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import { NavLink, withRouter } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import {  Button, Grid } from '@mui/material';
import EventRequestActionCard from "./eventrequest-action-card";
import EventRequestDetailCard from "./eventrequest-detail-card";
import EventLocationDataService from "../services/eventlocation.service";



const useStyles = theme => ({
    profileContainer: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
    },

    control: {
        padding: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});



class EventlocationRequestContent extends Component {

    constructor(props) {

        super(props);

        this.getEvenLocationRequests = this.getEvenLocationRequests.bind(this);
        this.state = {
            eventLocationRequests: [],
            detailsIndex: null,
            details: null
        };


    }


    componentDidMount() {
        this.getEvenLocationRequests();
    }

    async getToken() {
        const { getAccessTokenSilently } = this.props.auth0;
        let token = await getAccessTokenSilently();
        return token;
    }


    async getEvenLocationRequests() {
        let token = await this.getToken();
        EventLocationDataService.getEvenLocationRequests(token)
            .then(response => {

                this.setState({
                    eventLocationRequests: response.data
                });

            })
            .catch(e => {
                switch (e.response.status) {
                    case 403:
                        this.props.history.push("/forbidden");
                        break;
                    default:
                        break
                 }
                console.log(e);
            });
    }

    handleCallback = (childData) =>{
        //this.setState({details: childData})
        this.setState({detailsIndex: childData})
    }

    render() {

        const { eventLocationRequests, detailsIndex } = this.state;


        const { classes } = this.props;

        return (

            <div className={classes.profileContainer}>
                <br />
                <Button startIcon={<ArrowBackIosSharpIcon />} component={NavLink} to="/eventlocation" >Zurück</Button>
                <br />     <br />     <br />


                <Grid container spacing={2} >
                    <Grid item xs={3} style={{ overflowY: "scroll", maxHeight: "90vh", paddingTop: 0 }}  >
                        {eventLocationRequests.map((request, i) => (
                            <EventRequestActionCard key={"actionCard"+i} parentCallback = {this.handleCallback} details={request} index={i} />
                        ))}

                    </Grid>
                    <Grid item xs={9} style={{ maxHeight: "90vh", paddingTop: 0 }}>
                        <EventRequestDetailCard details={eventLocationRequests[detailsIndex]}/>
                    </Grid>
                </Grid>

            </div>
        );


    }
}

export default withStyles(useStyles)(withAuth0(withRouter(EventlocationRequestContent)))