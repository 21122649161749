import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from "@mui/icons-material/Add";
import Can from "../auth/Can";
import { NavLink, withRouter } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Grid,
  Button,
  TextField,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Skeleton,
  Link,
  Checkbox,
  FormControlLabel,
  Divider
} from "@mui/material";
import {
  DataGrid,
  /* GridToolbarContainer,
   GridToolbarExport,
   GridToolbarColumnsButton,
   GridToolbarFilterButton,*/
} from "@mui/x-data-grid";
import EventService from "../services/event.service";


const useStyles = (theme) => ({
  mainContainer: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  tilesAlignment: {
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(2),
  },
  tilesGroupAlignment: {
    paddingBottom: theme.spacing(8),
  },
  subTilesAlignment: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  labelAlignment: {
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(7),
  },
  backButton: {
    paddingLeft: theme.spacing(6),
    marginTop: theme.spacing(2),
  },
});


class EventsContent extends Component {
  constructor(props) {
    super(props);
    this.fetchEvents = this.fetchEvents.bind(this);
    this.state = {
      events: [],
      upcomingEvents: [],
      pastEvents: [],
      loading: true,
      searchQuery: "",
      sortBy: "start_date", // Default sort option
      activeTab: "upcoming", // Active tab (upcoming or past)
      filteredEvents: [], // Added filteredEvents state
      selectedEvent: null, // Added selectedEvent state
      isCopyDialogOpen: false,
      currentRowValues: {}, // Added isDeleteConfirmationOpen state
      selectedFields: {
        title: false,
        image: false,
        description: false,
        venue: false,
        organizer: false,
        tag_ids: false,
        category: false,
        url: false
      },
      selectAll: false
    };
  }

  fieldLabels = {
    title: 'Titel',
    image: 'Bild',
    description: 'Beschreibung',
    venue: 'Veranstaltungsort',
    organizer: 'Veranstalter',
    category: 'Event Typ / Kategorie',
    tag_ids: 'Schlagwörter / Tags',
    url: 'Link zu weiteren Informationen'

  };

  componentDidMount() {
    this.fetchEvents();
  }

  fetchEvents = async () => {
    let token = await this.getToken();
    EventService.getEvents(token)
      .then((response) => {
        const now = new Date();
        const events = response.data;
        const upcomingEvents = events.filter(
          (event) => new Date(event.start_date) >= now
        );
        const pastEvents = events.filter(
          (event) => new Date(event.start_date) < now
        );

        this.setState({
          events,
          upcomingEvents,
          pastEvents,
          loading: false,
          filteredEvents: upcomingEvents, // Set filteredEvents initially to upcomingEvents
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const { upcomingEvents, pastEvents, activeTab } = this.state;

    let filteredEvents;
    if (activeTab === "upcoming") {
      filteredEvents = upcomingEvents.filter(
        (event) =>
          event.title.toLowerCase().includes(query) ||
          event.description.toLowerCase().includes(query)
      );
    } else {
      filteredEvents = pastEvents.filter(
        (event) =>
          event.title.toLowerCase().includes(query) ||
          event.description.toLowerCase().includes(query)
      );
    }

    this.setState({ filteredEvents, searchQuery: query });
  };

  async getToken() {
    const { getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently();
    return token;
  }

  handleTabChange = (event, value) => {
    this.setState({ activeTab: value });
  };


  handleSortChange = (params) => {
    if (params.sortModel && params.sortModel.length > 0) {
      const { field, sort } = params.sortModel[0];
      const { filteredEvents } = this.state; // Get filteredEvents from state

      const sortedEvents = [...filteredEvents];

      if (sort === "asc") {
        sortedEvents.sort((a, b) => a[field].localeCompare(b[field]));
      } else if (sort === "desc") {
        sortedEvents.sort((a, b) => b[field].localeCompare(a[field]));
      }

      this.setState({ filteredEvents: sortedEvents });
    }
  };

  handleRowSelected = (params) => {
    const selectedEvent = params.row;
    this.props.history.push(`/events/${selectedEvent.id}`);
  };

  handleEventPreview = (event, cellValues) => {
    window.open(cellValues.row.url, '_blank');
  };

  handleOrginizerVenuePreview = (event, url) => {
    window.open(url, '_blank');
  };

  handleCopy = () => {

    const { history } = this.props;
    const { selectedFields, currentRowValues } = this.state;
    // Filter out the selected fields
    const selectedValues = Object.keys(selectedFields).reduce((acc, field) => {
      if (selectedFields[field]) {
        acc[field] = currentRowValues[field];
      }
      return acc;
    }, {});

    history.push({
      pathname: 'events/create',
      state: { selectedValues },
    });

  }

  handleEventCopy = (event, cellValues) => {
    this.setState({ isCopyDialogOpen: true, currentRowValues: cellValues.row });

    //console.log(cellValues.row)
  };

  handleSelectAllChange = (event) => {
    const selectAll = event.target.checked;
    this.setState({
      selectAll,
      selectedFields: Object.fromEntries(Object.keys(this.state.selectedFields).map((field) => [field, selectAll])),
    });
  };

  handleDialogClose = () => {
    this.setState({
      isCopyDialogOpen: false,
      selectedFields: {
        title: false,
        image: false,
        description: false,
        venue: false,
        organizer: false,
        tag_ids: false,
        category: false,
        url: false
      },
      selectAll: false,
    });
  };

  handleCheckboxChange = (field) => (event) => {
    this.setState((prevState) => ({
      selectedFields: {
        ...prevState.selectedFields,
        [field]: event.target.checked,
      },
    }));
  };

  /*handleRowSelected = (row) => {
  if(row.length>0) {
    this.setState({ selectedEvent: row })
  } else {
    this.setState({ selectedEvent: null })
  }
 
}

handleDeleteEvent = async () => {
  const { activeEvents, selectedEvent, filteredEvents } = this.state;
 
  if (selectedEvent.length === 0) {
    // Handle the case where no events are selected
    return;
  }
 
  // Perform delete action on the selected events using their IDs (e.g., using EventService)
  // ...
 
  // Remove the deleted events from the filteredEvents
  const updatedEvents = filteredEvents.filter((item) => !selectedEvent.includes(item.id));
 
  this.setState({
    activeEvents: updatedEvents,
    selectedEvent: [],
    isDeleteConfirmationOpen: false
  });

  await this.fetchEvents();
};

handleEditEvent = () => {
  const { selectedEvent } = this.state;
  // Perform edit action on selectedEvent (e.g., navigate to edit page)
  // ...
};


handleDeleteConfirmationOpen = () => {
  this.setState({ isDeleteConfirmationOpen: true });
};

handleDeleteConfirmationClose = () => {
  this.setState({ isDeleteConfirmationOpen: false });
};*/



  render() {
    /* const CustomToolbar = () => {
       return (
         <GridToolbarContainer>
           <div style={{ flexGrow: 1 }} />
           <Button
 
             startIcon={<DeleteIcon />}
             onClick={this.handleDeleteConfirmationOpen}
             disabled={!this.state.selectedEvent}
           >
             Delete
           </Button>
           <Button
 
             startIcon={<EditIcon />}
             onClick={this.handleEditEvent}
             disabled={!this.state.selectedEvent}
           >
             Edit
           </Button>
           <GridToolbarColumnsButton />
           <GridToolbarFilterButton />
           <GridToolbarExport />
         </GridToolbarContainer>
       );
     };*/

    const { classes } = this.props;
    const { user } = this.props.auth0;
  

    const {
      upcomingEvents,
      pastEvents,
      loading,
      searchQuery,
      activeTab,
      isCopyDialogOpen,
      selectedFields,
      selectAll
    } = this.state;

    const isCopyButtonDisabled = Object.values(selectedFields).every((field) => !field);


    const formatDate = (params) => {
      const date = new Date(params.value);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return date.toLocaleString("de-DE", options);
    };

    const columns = [
      {
        field: "title",
        headerName: "Titel",
        flex: 1
      },
      // { field: "description", headerName: "Description", flex: 1 },
      {
        field: "start_date",
        headerName: "Event Start",
        flex: 1,
        valueFormatter: formatDate,
      },
      {
        field: "end_date",
        headerName: "Event Ende",
        flex: 1,
        valueFormatter: formatDate,
      },
      //{ field: "status", headerName: "Status", flex: 1 },
      {
        field: "venue",
        headerName: "Veranstaltungsort",
        width: 250,
        //valueGetter: (params) => params.row.venue?.venue,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <Link style={{ display: 'flex', alignItems: 'center' }} component="button" onClick={(event) => {
            this.handleOrginizerVenuePreview(event, params.row.venue?.url);
            event.stopPropagation();
          }}>{params.row.venue?.venue} <span style={{ width: "4px" }}></span>  <OpenInNewIcon style={{ fontSize: '16px' }} /></Link>

        )
      },
      {
        field: "organizer",
        headerName: "Veranstalter",
        width: 250,
        //valueGetter: (params) => params.row.organizer?.organizer,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <Link style={{ display: 'flex', alignItems: 'center' }} component="button" onClick={(event) => {
            this.handleOrginizerVenuePreview(event, params.row.organizer?.url);
            event.stopPropagation();
          }}>{params.row.organizer?.organizer} <span style={{ width: "4px" }}></span>  <OpenInNewIcon style={{ fontSize: '16px' }} /></Link>

        )
      },
      {
        field: "copy",
        headerName: "Kopieren",
        width: 160,
        //valueGetter: (params) => params.row.organizer?.organizer,
        disableClickEventBubbling: true,
        renderCell: (cellValues) => (
          <Button
            variant="outlined"
            color="info"
            size="small"
            endIcon={<ContentCopyIcon />}
            onClick={(event) => {
              this.handleEventCopy(event, cellValues);
              event.stopPropagation();
            }}
          >
            Kopieren
          </Button>

        )
      },
      {
        field: 'actions',
        headerName: 'Event anzeigen',
        width: 130,
        disableClickEventBubbling: true,
        renderCell: (cellValues) => (
          <Button
            variant="outlined"
            color="info"
            size="small"
            endIcon={<VisibilityIcon />}
            onClick={(event) => {
              this.handleEventPreview(event, cellValues);
              event.stopPropagation();
            }}
          >
            Anzeigen
          </Button>
        ),
      },
      // Add more columns if needed
    ];

    let activeEvents = upcomingEvents;
    if (activeTab === "past") {
      activeEvents = pastEvents;
    }

    if (searchQuery) {
      activeEvents = activeEvents.filter(
        (event) =>
          event.title.toLowerCase().includes(searchQuery) ||
          event.description.toLowerCase().includes(searchQuery)
      );
    }

    return (

      <Can
        user={user}
        perform="EventManager"
        no={() => {
          this.props.history.push("/forbidden");
          return null
        }}
        yes={() => (
          <div className={classes.mainContainer}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  className={classes.backButton}
                  startIcon={<ArrowBackIosSharpIcon />}
                  component={NavLink}
                  to="/"
                >
                  Zurück
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', marginTop: 20, marginBottom: 20 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  endIcon={<AddIcon />}
                  component={NavLink}
                  to="/events/create"
                >
                  Neues Event anlegen
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                <TextField
                  label="Events suchen"
                  variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={this.handleSearch}
                />

              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Tabs value={activeTab} onChange={this.handleTabChange}>
                  <Tab label="Anstehende Events" value="upcoming" />
                  <Tab label="Vergangene Events" value="past" />
                </Tabs>
                {loading ? (
                  <Grid container spacing={2} marginTop={5}>
                    <Grid item xs={12} sm={6} md={8} lg={9}>
                      <Skeleton animation="wave" width="80%" height={30} />
                      <Skeleton animation="wave" width="60%" height={20} />
                      <Skeleton animation="wave" width="50%" height={20} />
                      <Skeleton animation="wave" width="70%" height={20} />
                      <Skeleton animation="wave" width="66%" height={20} />
                      <Skeleton animation="wave" width="56%" height={20} />
                      <Skeleton animation="wave" width="75%" height={20} />
                    </Grid>
                  </Grid>
                ) : (
                  <div style={{ height: 490, width: "100%" }}>

                    <DataGrid
                      rows={activeEvents}
                      columns={columns}
                      components={{
                        // Toolbar: CustomToolbar,


                      }}
                      sortingOrder={["asc", "desc"]}
                      onSortModelChange={this.handleSortChange}
                      onRowClick={this.handleRowSelected}
                      localeText={{ noRowsLabel: 'Keine Events vorhanden' }}
                    // checkboxSelection
                    // disableSelectionOnClick
                    // onSelectionModelChange={this.handleRowSelected}

                    />

                  </div>
                )}
              </Grid>
            </Grid>
            <Dialog open={isCopyDialogOpen} onClose={this.handleDialogClose} maxWidth="sm" fullWidth>
              <DialogTitle>Folgende Infos vom Event kopieren</DialogTitle>
              <DialogContent>

                <FormControlLabel
                  control={<Checkbox checked={selectAll} style={{ color: '#3f51b5' }} onChange={this.handleSelectAllChange} />}
                  label={
                    <span style={{ color: '#3f51b5' }}>Alles auswählen</span>
                  }
                />
                <Divider />
                {Object.keys(selectedFields).map((field) => (
                  <div key={field}>
                    <FormControlLabel
                      control={<Checkbox checked={selectedFields[field]} onChange={this.handleCheckboxChange(field)} />}
                      label={this.fieldLabels[field]}
                    />
                  </div>
                ))}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCopy} disabled={isCopyButtonDisabled}>Kopieren</Button>
                <Button onClick={this.handleDialogClose}>Abbrechen</Button>
              </DialogActions>
            </Dialog>

          </div>
        )}
      />

    );
  }
}

export default withStyles(useStyles)(withAuth0(withRouter(EventsContent)));
