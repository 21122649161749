import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import { NavLink, withRouter } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import { DataGrid } from '@mui/x-data-grid';
import EventLocationDataService from "../services/eventlocation.service";
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import uuid from 'react-uuid';

import {
    Typography,
    InputAdornment,
    Button,
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    Paper,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    FormGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    Stack,
    IconButton,
    Select,
    InputLabel,
    MenuItem,
    ImageList,
    ImageListItem,
    Skeleton
} from '@mui/material';

const defaultTheme = createTheme();

const useStyles2 = makeStyles(
    (theme) => ({
        root: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            color: theme.palette.text.secondary,
        },
        textPrimary: {
            color: theme.palette.text.primary,
        },
    }),
    { defaultTheme },
);


const useStyles = theme => ({
    mainContainer: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        marginTop: theme.spacing(3)
    },
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    startEndDate: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(0)

    },
    weekly: {
        transform: "scale(1.3)"
    },
    centerImage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "20px"
    }
});



function RowMenuCell(props) {
    const { api, id } = props;
    const classes = useStyles2();
    const isInEditMode = api.getRowMode(id) === 'edit';
    const that = this;

    const handleEditClick = (event) => {
        event.stopPropagation();
        api.setRowMode(id, 'edit');
    };

    const handleSaveClick = (event, that) => {
        event.stopPropagation();
        api.commitRowChange(id);
        api.setRowMode(id, 'view');

        const row = api.getRow(id);
        api.updateRows([{ ...row, isNew: false }]);

        let updatedRooms = that.state.eventRooms;

        updatedRooms.forEach(function (e) {
            if (row.id && row.id === e.id) {
                for (var i in row) e[i] = row[i]
            }
        })



        that.setState({ eventRooms: updatedRooms });

        //new EventlocationProfileContent().handleRowSave(row, id, props);
    };

    const handleDeleteClick = (event, that) => {
        event.stopPropagation();

        const row = api.getRow(id);
        api.updateRows([{ id, _action: 'delete' }]);

        let deletedRooms = that.state.eventRooms;

        deletedRooms.map(obj => {
            if (obj.id === row.id) {
                //return {...obj, deleted: true};
                obj.deleted = true;
            }
            return obj;
        });



        that.setState({ eventRooms: deletedRooms });

    };

    const handleCancelClick = (event) => {
        event.stopPropagation();
        api.setRowMode(id, 'view');

        const row = api.getRow(id);
        if (row.isNew) {
            api.updateRows([{ id, _action: 'delete' }]);
        }
    };

    if (isInEditMode) {
        return (
            <div className={classes.root}>
                <IconButton
                    color="primary"
                    size="small"
                    aria-label="save"
                    onClick={event => handleSaveClick(event, that)}
                >
                    <SaveIcon fontSize="small" />
                </IconButton>
                <IconButton
                    color="inherit"
                    size="small"
                    aria-label="cancel"
                    className={classes.textPrimary}
                    onClick={handleCancelClick}
                >
                    <CancelIcon fontSize="small" />
                </IconButton>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <IconButton
                color="inherit"
                className={classes.textPrimary}
                size="small"
                aria-label="edit"
                onClick={handleEditClick}
            >
                <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
                color="inherit"
                size="small"
                aria-label="delete"
                onClick={event => handleDeleteClick(event, that)}
            >
                <DeleteIcon fontSize="small" />
            </IconButton>
        </div>
    );
}

RowMenuCell.propTypes = {
    api: PropTypes.object.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export { RowMenuCell };


class EventlocationProfileContent extends Component {



    constructor(props) {

        super(props);
        //this.myTableRef = React.createRef();
        this.getEventLocation = this.getEventLocation.bind(this);
        this.getEventLocationImages = this.getEventLocationImages.bind(this);
        this.state = {
            publishChanges: false,
            dialogOpen: false,
            locationId: "",
            locationName: "",
            locationDescription: "",
            peopleMin: "",
            peopleMax: "",
            priceMin: "",
            priceMax: "",
            priceDetails: "",
            eventTypes: [
                'Private-Feier',
                'Familienfeier',
                'Geburtstag',
                'Hochzeit',
                'Weihnachtsfeier',
                'Silvester',
                'Firmenevent',
                'Teamevent',
                'Party',
                'Trauerfeier',
                'Konfirmation',
                'Taufe',
                'Stammtisch',
                'Besprechungen'
            ],
            eventTypeChecked: [false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            eventDrinks: [
                'open Bar (pay what you drink)',
                'á la Carte',
                'Bring your own drinks',
                'Begrüßungsdrinks (Aperitif)',
                'Sektempfang',
                'all you can drink',
                'Cocktails'
            ],
            eventDrinksChecked: [false, false, false, false, false, false, false],
            nutrition: [
                'Vegan',
                'Vegetarisch',
                'Glutenfrei',
                'Laktosefrei',
                'Halal',
                'nach Absprache'
            ],
            nutritionChecked: [false, false, false, false, false, false],
            menuType: [
                'á la Carte',
                'individuelles Menü nach Absprache',
                'Buffet',
                'Bring your own food',
                'Snacks und Fingerfood',
                'Küche vorhanden'
            ],
            menuTypeChecked: [false, false, false, false, false, false],
            amenitiesFeatures: [
                'Musikanlage',
                'Event Dekoration',
                'Sektempfang',
                'Geschlossene Gesellschaft möglich',
                'Live-Musik möglich'
            ],
            amenitiesFeaturesChecked: [false, false, false, false, false],
            selectAll: false,
            selectAllDrinks: false,
            selectAllNutrition: false,
            selectAllMenuType: false,
            selectAllAmenitiesFeatures: false,
            cateringOption: 'with',
            serviceOption: 'with',
            nrOfRooms: 0,
            propertyType: "",
            availibility: "",
            roomDistribution: "",
            locationExists: false,
            eventRooms: [],
            images: [],
            logo: "",
            loading: true
            /* [
                { id: 1, room: 'Snow', nrSeats: 35, chairs: "available", notes: "blabl" },
                { id: 2, room: 'Snow', nrSeats: 35, chairs: "available", notes: "blabl" }
             ] */

        };

        this.columns = [
            { field: 'room', headerName: 'Raum', editable: true, width: 170, align: 'left', headerAlign: "left" },
            { field: 'nrOfPlaces', headerName: 'Anzahl Plätze', editable: true, width: 120, align: 'left', headerAlign: "left", type: 'number' },
            { field: 'seats', headerName: 'Bestuhlung', editable: true, width: 200, align: 'left', headerAlign: "left" },
            { field: 'notes', headerName: 'Bemerkung', editable: true, width: 200, align: 'left', headerAlign: "left" },
            {
                field: 'actions',
                headerName: 'Bearbeiten',
                renderCell: RowMenuCell.bind(this),
                sortable: false,
                width: 120,
                headerAlign: 'left',
                filterable: false,
                align: 'left',
                disableColumnMenu: true,
                disableReorder: true,
            }
        ];



    }



    componentDidMount() {
        this.getEventLocation();
        this.getEventLocationImages();
    }

    async getToken() {
        const { getAccessTokenSilently } = this.props.auth0;
        let token = await getAccessTokenSilently();
        return token;
    }

    async getEventLocationImages() {
        let token = await this.getToken();
        EventLocationDataService.getEventLocationImages(token)
            .then(response => {
                if (response.data) {
                    this.setState({
                        images: response.data.images,
                        logo: response.data.logo,
                        loading: false
                    });
                }

            })
            .catch(e => {
                if (e.response) {
                    switch (e.response.status) {
                        case 403:
                            this.props.history.push("/forbidden");
                            break
                        default:
                            break
                    }
                }
                console.log(e);
            });
    }


    async getEventLocation() {
        let token = await this.getToken();
        EventLocationDataService.getEventLocation(token)
            .then(response => {
                if (response.data.length > 0) {

                    this.setState({
                        locationId: response.data[0].id,
                        locationName: response.data[0].locationName,
                        locationDescription: response.data[0].locationDescription,
                        peopleMin: response.data[0].peopleMin,
                        peopleMax: response.data[0].peopleMax,
                        priceMin: response.data[0].priceMin,
                        priceMax: response.data[0].priceMax,
                        priceDetails: response.data[0].priceDetails,
                        cateringOption: response.data[0].cateringOption,
                        serviceOption: response.data[0].serviceOption,
                        nrOfRooms: response.data[0].nrOfRooms,
                        propertyType: response.data[0].propertyType,
                        availibility: response.data[0].availibility,
                        roomDistribution: response.data[0].roomDistribution,
                        eventTypeChecked: response.data[0].eventTypeChecked.split(',').map(j => JSON.parse(j)),
                        eventDrinksChecked: response.data[0].eventDrinksChecked.split(',').map(j => JSON.parse(j)),
                        nutritionChecked: response.data[0].nutritionChecked.split(',').map(j => JSON.parse(j)),
                        menuTypeChecked: response.data[0].menuTypeChecked.split(',').map(j => JSON.parse(j)),
                        amenitiesFeaturesChecked: response.data[0].amenitiesFeaturesChecked ? response.data[0].amenitiesFeaturesChecked.split(',').map(j => JSON.parse(j)) : "",
                        locationExists: true,
                        eventRooms: response.data[0].eventRooms

                    });
                }

            })
            .catch(e => {
                if (e.response) {
                    switch (e.response.status) {
                        case 403:
                            this.props.history.push("/forbidden");
                            break
                        default:
                            break
                    }
                }
                console.log(e);
            });
    }

    async updateEventLocation(data) {

        let token = await this.getToken();

        data.eventRooms = this.removeEmptyRooms(data.eventRooms);

        if (!this.state.locationExists) {
            EventLocationDataService.createEventLocation(data, token).then(response => {
                console.log("eventlocation successfully created");
                this.setState({ publishChanges: true });
            })
                .catch(e => {
                    console.log(e);
                });

        } else {

            EventLocationDataService.updateEventLocation(data, token).then(response => {
                console.log("eventlocation successfully updated");
                this.setState({ publishChanges: true });
            })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    removeEmptyRooms(rooms) {

        let result = rooms.filter(o => (o.notes !== "" || o.room !== "" || o.seats !== "" || o.nrOfPlaces !== null));
        return result;

    };

    publishLocationProfile = () => {
        this.setState({ dialogOpen: true });
        //this.updateEventLocation(this.state);
    };


    handleAddRoom = () => {

        //let newId =  Math.max(...this.state.eventRooms.map(o => o.id)) + 1 ;
        this.setState({ eventRooms: [...this.state.eventRooms.filter(o => o.deleted !== true), { id: uuid(), room: '', nrOfPlaces: null, seats: "", notes: "", deleted: false, eventlocationId: this.state.locationId }] });

        //console.log(columns);

        //this.myButton();
        //const id = randomId();
        //apiRef.current.updateRows([{ id, isNew: true }]);
        //apiRef.current.setRowMode(id, 'edit');
        // Wait for the grid to render with the new row
        /*setTimeout(() => {
            apiRef.current.scrollToIndexes({
                rowIndex: apiRef.current.getRowsCount() - 1,
            });

            apiRef.current.setCellFocus(id, 'name');
        }, 150);*/
    };


    handleRowSave = (row, id, props) => {
        // const { api } = props;
        //this.setState({ eventRooms: [api.state.rows.idRowsLookup] });
    };

    handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    handleChange = (e, index) => {

        const { checked } = e.target;
        const list = [...this.state.eventTypeChecked];
        list[index] = checked;
        this.setState({ eventTypeChecked: list });
    };

    handleChangeDrinks = (e, index) => {

        const { checked } = e.target;
        const list = [...this.state.eventDrinksChecked];
        list[index] = checked;
        this.setState({ eventDrinksChecked: list });
    };

    handleChangeNutrition = (e, index) => {

        const { checked } = e.target;
        const list = [...this.state.nutritionChecked];
        list[index] = checked;
        this.setState({ nutritionChecked: list });
    };

    handleChangeMenuType = (e, index) => {

        const { checked } = e.target;
        const list = [...this.state.menuTypeChecked];
        list[index] = checked;
        this.setState({ menuTypeChecked: list });
    };

    handleChangeAmenitiesFeatures = (e, index) => {

        const { checked } = e.target;
        const list = [...this.state.amenitiesFeaturesChecked];
        list[index] = checked;
        this.setState({ amenitiesFeaturesChecked: list });
    };


    handleAlleSelectDrinks = (e) => {
        const { checked } = e.target;
        const list = [...this.state.eventDrinksChecked];
        list.fill(checked);
        this.setState({ eventDrinksChecked: list, selectAllDrinks: checked });
    };

    handleAlleSelectNutrition = (e) => {
        const { checked } = e.target;
        const list = [...this.state.nutritionChecked];
        list.fill(checked);
        this.setState({ nutritionChecked: list, selectAllNutrition: checked });
    };

    handleAlleSelectMenuType = (e) => {
        const { checked } = e.target;
        const list = [...this.state.menuTypeChecked];
        list.fill(checked);
        this.setState({ menuTypeChecked: list, selectAllMenuType: checked });
    };

    handleAlleSelectAmenitiesFeatures = (e) => {
        const { checked } = e.target;
        const list = [...this.state.amenitiesFeaturesChecked];
        list.fill(checked);
        this.setState({ amenitiesFeaturesChecked: list, selectAllAmenitiesFeatures: checked });
    };


    handleAlleSelect = (e) => {
        const { checked } = e.target;
        const list = [...this.state.eventTypeChecked];
        list.fill(checked);
        this.setState({ eventTypeChecked: list, selectAll: checked });
    };

    handleCateringChange = (e) => {
        this.setState({ cateringOption: e.target.value });
    };

    handleServiceChange = (e) => {
        this.setState({ serviceOption: e.target.value });
    };


    handleLocationNameChange = (e) => {
        const { value } = e.target;
        this.setState({ locationName: value });
    };


    handleLocationDescriptionChange = (e) => {
        const { value } = e.target;
        this.setState({ locationDescription: value });
    };


    handlePeopleMinChange = (e) => {
        const { value } = e.target;
        if (value < 0) {
            this.setState({ peopleMin: Math.abs(value) });
        } else {
            this.setState({ peopleMin: value });
        }

    };


    handlePeopleMaxChange = (e) => {
        const { value } = e.target;
        if (value < 0) {
            this.setState({ peopleMax: Math.abs(value) });
        } else {
            this.setState({ peopleMax: value });
        }
    };


    handlePriceMinChange = (e) => {
        const { value } = e.target;
        if (value < 0) {
            this.setState({ priceMin: Math.abs(value) });
        } else {
            this.setState({ priceMin: value });
        }
    };


    handlePriceMaxChange = (e) => {
        const { value } = e.target;
        if (value < 0) {
            this.setState({ priceMax: Math.abs(value) });
        } else {
            this.setState({ priceMax: value });
        }
    };


    handlePriceDetailsChange = (e) => {
        const { value } = e.target;
        this.setState({ priceDetails: value });
    };

    handleNrOfRoomsChange = (e) => {
        const { value } = e.target;
        if (value < 0) {
            this.setState({ nrOfRooms: Math.abs(value) });
        } else {
            this.setState({ nrOfRooms: value });
        }

    };

    handlePropertyTypeChange = (e) => {
        const { value } = e.target;
        this.setState({ propertyType: value });

    };

    handleAvailibilityChange = (e) => {
        const { value } = e.target;
        this.setState({ availibility: value });
    };

    handleRoomDistributionChange = (e) => {
        const { value } = e.target;
        this.setState({ roomDistribution: value });
    };

    handleDialogClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ dialogOpen: false });
    };




    render() {

        const { classes } = this.props;
        const { eventTypes, eventDrinks, nutrition, menuType, menuTypeChecked, amenitiesFeatures, amenitiesFeaturesChecked, eventTypeChecked, eventDrinksChecked, nutritionChecked, selectAll, selectAllDrinks, selectAllNutrition, selectAllMenuType, selectAllAmenitiesFeatures, cateringOption, serviceOption, locationName, locationDescription, priceMin, priceMax, priceDetails, peopleMin, peopleMax, nrOfRooms, propertyType, availibility, roomDistribution, dialogOpen, publishChanges, eventRooms, images, logo, loading } = this.state;

        return (

            <div className={classes.mainContainer}>
                <br />
                <Button startIcon={<ArrowBackIosSharpIcon />} component={NavLink} to="/eventlocation" >Zurück</Button>
                <br />     <br />     <br />

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={9} lg={9} >
                            <Paper variant="outlined" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 3 }, width: 1, }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={8}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.publishLocationProfile}
                                            endIcon={<SaveIcon />}
                                        >
                                            Speichern
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Details zu Veranstaltungen in deiner Location
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="locationName"
                                            label="Location Name"
                                            fullWidth
                                            disabled={true}
                                            variant="outlined"
                                            value={locationName}
                                            onChange={this.handleLocationNameChange}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            required
                                            id="locationDescription"
                                            label="Beschreibung"
                                            multiline
                                            fullWidth
                                            rows={6}
                                            variant="outlined"
                                            value={locationDescription}
                                            onChange={this.handleLocationDescriptionChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} sx={{ marginTop: "30px" }}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <FormLabel component="legend">Preis pro Person (ungefähr)</FormLabel>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormLabel component="legend">Anzahl Personen</FormLabel>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container>
                                            <Grid item xs={4} >
                                                <Grid container spacing={3}>
                                                    <Grid item xs={4} sm={4}>
                                                        <TextField
                                                            id="min-price"
                                                            label="min"
                                                            type="number"
                                                            variant="outlined"
                                                            value={priceMin}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                            }}
                                                            onChange={this.handlePriceMinChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4}>

                                                        <TextField
                                                            id="max-price"
                                                            label="max"
                                                            type="number"
                                                            variant="outlined"
                                                            value={priceMax}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                            }}
                                                            onChange={this.handlePriceMaxChange}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={4} sm={4}>
                                                        <TextField
                                                            id="min-number-people"
                                                            label="min"
                                                            type="number"
                                                            variant="outlined"
                                                            value={peopleMin}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handlePeopleMinChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4}>

                                                        <TextField
                                                            id="max-number-people"
                                                            label="max"
                                                            type="number"
                                                            variant="outlined"
                                                            value={peopleMax}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handlePeopleMaxChange}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={8} sx={{ marginTop: "20px" }}>
                                        <TextField
                                            label="Preis Zusatzinfo"
                                            id="pricedetails"
                                            multiline
                                            fullWidth
                                            rows={3}
                                            variant="outlined"
                                            value={priceDetails}
                                            onChange={this.handlePriceDetailsChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sx={{ marginTop: "30px" }}>
                                        <FormLabel component="legend">Event Art</FormLabel>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="selectAll" checked={selectAll} onChange={e => this.handleAlleSelect(e)} />}
                                            label="Alle selektieren"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl sx={{ m: 0 }} component="fieldset" variant="outlined">

                                            <FormGroup>

                                                {eventTypes.slice(0, 5).map((eventType, i) => (
                                                    <FormControlLabel key={"eventypekey" + i}
                                                        control={
                                                            <Checkbox key={"eventype" + i} checked={eventTypeChecked[i]} onChange={e => this.handleChange(e, i)} name="eventType" />
                                                        }
                                                        label={eventType}
                                                    />
                                                ))}
                                            </FormGroup>

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl sx={{ m: 0 }} component="fieldset" variant="outlined">
                                            <FormGroup>

                                                {eventTypes.slice(5, 10).map((eventType, i) => (
                                                    <FormControlLabel key={"eventypekey" + i + 5}
                                                        control={
                                                            <Checkbox key={"eventype" + i + 5} checked={eventTypeChecked[5 + i]} onChange={e => this.handleChange(e, 5 + i)} name="eventType" />
                                                        }
                                                        label={eventType}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl sx={{ m: 0 }} component="fieldset" variant="outlined">
                                            <FormGroup>

                                                {eventTypes.slice(10, 15).map((eventType, i) => (
                                                    <FormControlLabel key={"eventypekey" + i + 10}
                                                        control={
                                                            <Checkbox key={"eventype" + i + 10} checked={eventTypeChecked[10 + i]} onChange={e => this.handleChange(e, 10 + i)} name="eventType" />
                                                        }
                                                        label={eventType}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} sx={{ marginTop: "30px" }}>
                                        <FormControl>
                                            <FormLabel id="catering-option">Catering</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="catering-option"
                                                name="row-radio-buttons-catering-option"
                                                onChange={e => this.handleCateringChange(e)}
                                                value={cateringOption}
                                            >
                                                <FormControlLabel value="with" control={<Radio />} label="mit Catering" />
                                                <FormControlLabel value="without" control={<Radio />} label="ohne Catering" />
                                                <FormControlLabel value="other" control={<Radio />} label="Catering bei Bedarf" />
                                            </RadioGroup>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={12} sm={12} sx={{ marginTop: "30px" }}>
                                        <FormControl>
                                            <FormLabel id="service-option">Personal</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="service-option"
                                                name="row-radio-buttons-service-option"
                                                onChange={e => this.handleServiceChange(e)}
                                                value={serviceOption}
                                            >
                                                <FormControlLabel value="with" control={<Radio />} label="mit Servicepersonal" />
                                                <FormControlLabel value="without" control={<Radio />} label="ohne Servicepersonal" />
                                                <FormControlLabel value="other" control={<Radio />} label="Servicepersonal bei Bedarf" />
                                            </RadioGroup>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: "30px" }}>
                                        <FormLabel component="legend">Drinks</FormLabel>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="selectAllDrinks" checked={selectAllDrinks} onChange={e => this.handleAlleSelectDrinks(e)} />}
                                            label="Alle selektieren"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} >
                                        <FormControl sx={{ m: 0 }} component="fieldset" variant="outlined">
                                            <FormGroup>

                                                {eventDrinks.slice(0, 4).map((eventDrinks, i) => (
                                                    <FormControlLabel key={"evendrinkkey" + i}
                                                        control={
                                                            <Checkbox key={"eventdrink" + i} checked={eventDrinksChecked[i]} onChange={e => this.handleChangeDrinks(e, i)} name="eventDrinks" />
                                                        }
                                                        label={eventDrinks}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl sx={{ m: 0 }} component="fieldset" variant="outlined">
                                            <FormGroup>

                                                {eventDrinks.slice(4, 10).map((eventDrinks, i) => (
                                                    <FormControlLabel key={"evendrinkkey" + i + 4}
                                                        control={
                                                            <Checkbox key={"eventdrink" + i + 4} checked={eventDrinksChecked[4 + i]} onChange={e => this.handleChangeDrinks(e, 4 + i)} name="eventDrinks" />
                                                        }
                                                        label={eventDrinks}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: "30px" }}>
                                        <FormLabel component="legend">Ernährungsarten</FormLabel>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="selectAllNutrition" checked={selectAllNutrition} onChange={e => this.handleAlleSelectNutrition(e)} />}
                                            label="Alle selektieren"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl sx={{ m: 0 }} component="fieldset" variant="outlined">
                                            <FormGroup>

                                                {nutrition.slice(0, 3).map((nutrition, i) => (
                                                    <FormControlLabel key={"nutritionkey" + i}
                                                        control={
                                                            <Checkbox key={"nutrition" + i} checked={nutritionChecked[i]} onChange={e => this.handleChangeNutrition(e, i)} name="nutrition" />
                                                        }
                                                        label={nutrition}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl sx={{ m: 0 }} component="fieldset" variant="outlined">
                                            <FormGroup>

                                                {nutrition.slice(3, 10).map((nutrition, i) => (
                                                    <FormControlLabel key={"nutritionkey" + i + 3}
                                                        control={
                                                            <Checkbox key={"nutrition" + i + 3} checked={nutritionChecked[3 + i]} onChange={e => this.handleChangeNutrition(e, 3 + i)} name="nutrition" />
                                                        }
                                                        label={nutrition}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: "30px" }}>
                                        <FormLabel component="legend">Speisekarte</FormLabel>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="selectAllMenuType" checked={selectAllMenuType} onChange={e => this.handleAlleSelectMenuType(e)} />}
                                            label="Alle selektieren"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl sx={{ m: 0 }} component="fieldset" variant="outlined">
                                            <FormGroup>

                                                {menuType.slice(0, 3).map((menuType, i) => (
                                                    <FormControlLabel key={"menutypekey" + i}
                                                        control={
                                                            <Checkbox key={"menuType" + i} checked={menuTypeChecked[i]} onChange={e => this.handleChangeMenuType(e, i)} name="menuType" />
                                                        }
                                                        label={menuType}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl sx={{ m: 0 }} component="fieldset" variant="outlined">
                                            <FormGroup>

                                                {menuType.slice(3, 10).map((menuType, i) => (
                                                    <FormControlLabel key={"menutypekey" + i + 3}
                                                        control={
                                                            <Checkbox key={"menuType" + i + 3} checked={menuTypeChecked[3 + i]} onChange={e => this.handleChangeMenuType(e, 3 + i)} name="menuType" />
                                                        }
                                                        label={menuType}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: "30px" }}>
                                        <FormLabel component="legend">Ausstattung und Besonderheiten</FormLabel>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="selectAllAmenitiesFeatures" checked={selectAllAmenitiesFeatures} onChange={e => this.handleAlleSelectAmenitiesFeatures(e)} />}
                                            label="Alle selektieren"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl sx={{ m: 0 }} component="fieldset" variant="outlined">
                                            <FormGroup>

                                                {amenitiesFeatures.slice(0, 3).map((amenitiesFeatures, i) => (
                                                    <FormControlLabel key={"amenitiesFeatureskey" + i}
                                                        control={
                                                            <Checkbox key={"amenitiesFeatures" + i} checked={amenitiesFeaturesChecked[i]} onChange={e => this.handleChangeAmenitiesFeatures(e, i)} name="amenitiesFeatures" />
                                                        }
                                                        label={amenitiesFeatures}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl sx={{ m: 0 }} component="fieldset" variant="outlined">
                                            <FormGroup>

                                                {amenitiesFeatures.slice(3, 10).map((amenitiesFeatures, i) => (
                                                    <FormControlLabel key={"amenitiesFeatureskey" + i + 3}
                                                        control={
                                                            <Checkbox key={"amenitiesFeatures" + i + 3} checked={amenitiesFeaturesChecked[3 + i]} onChange={e => this.handleChangeAmenitiesFeatures(e, 3 + i)} name="amenitiesFeatures" />
                                                        }
                                                        label={amenitiesFeatures}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: "30px" }}>

                                        <FormControl sx={{ width: '220px' }}>
                                            <InputLabel id="propertyType-label">Objekt Art</InputLabel>
                                            <Select
                                                labelId="propertyType-label"
                                                id="propertyType"
                                                value={propertyType}
                                                label="Objekt Art"
                                                defaultValue={1}
                                                onChange={this.handlePropertyTypeChange}
                                            >
                                                
                                                <MenuItem value="Apartment">Apartment</MenuItem>
                                                <MenuItem value="Bar">Bar</MenuItem>
                                                <MenuItem value="Boot">Boot</MenuItem>
                                                <MenuItem value="Chalet">Chalet</MenuItem>
                                                <MenuItem value="Club">Club</MenuItem>
                                                <MenuItem value="Coworking Space">Coworking Space</MenuItem>
                                                <MenuItem value="Garten">Garten</MenuItem>
                                                <MenuItem value="Lounge">Lounge</MenuItem>
                                                <MenuItem value="Restaurant">Restaurant</MenuItem>
                                                <MenuItem value="Schiff">Schiff</MenuItem>
                                                <MenuItem value="Stadthaus">Stadthaus</MenuItem>
                                                <MenuItem value="Weinbar">Weinbar</MenuItem>
                                                <MenuItem value="Weingut">Weingut</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: "30px" }}>
                                        <FormControl sx={{ width: '100px' }}>
                                            <InputLabel id="nrOfRooms-label">Räume</InputLabel>
                                            <Select
                                                labelId="nrOfRooms-label"
                                                id="nrOfRooms"
                                                value={nrOfRooms}
                                                label="Räume"
                                                defaultValue={1}
                                                onChange={this.handleNrOfRoomsChange}
                                            >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem>
                                                <MenuItem value={7}>7</MenuItem>
                                                <MenuItem value={8}>8</MenuItem>
                                                <MenuItem value={9}>9</MenuItem>
                                                <MenuItem value={10}>10</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>

                                        <Box sx={{ width: '100%' }}>
                                            <Stack direction="row" spacing={1}>
                                                <Button color="primary" startIcon={<AddIcon />} onClick={this.handleAddRoom}>
                                                    Raum hinzufügen
                                                </Button>
                                            </Stack>
                                            <Box sx={{ height: 300, mt: 1 }}>
                                                <DataGrid
                                                    editMode="row"
                                                    rows={eventRooms}
                                                    columns={this.columns}
                                                    onRowEditStart={this.handleRowEditStart}
                                                    onRowEditStop={this.handleRowEditStop}
                                                    ref="DataGrid"
                                                    hideFooter={true}

                                                />
                                            </Box>
                                        </Box>


                                        <TextField sx={{ marginTop: "50px" }}
                                            label="Raumaufteilung"
                                            id="roomdistribution"
                                            multiline
                                            fullWidth
                                            rows={3}
                                            variant="outlined"
                                            value={roomDistribution}
                                            onChange={this.handleRoomDistributionChange}
                                        />


                                    </Grid>
                                    <Grid item xs={8} sx={{ marginTop: "30px" }}>
                                        <TextField
                                            id="availibility"
                                            label="Verfügbarkeit"
                                            multiline
                                            fullWidth
                                            rows={6}
                                            variant="outlined"
                                            value={availibility}
                                            onChange={this.handleAvailibilityChange}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.publishLocationProfile}
                                            endIcon={<SaveIcon />}
                                        >
                                            Speichern
                                        </Button>
                                    </Grid>

                                </Grid>

                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Paper variant="outlined" sx={{ my: { xs: 3, md: 1 }, p: { xs: 2, md: 3 }, width: 1, }}>
                                {loading ? (

                                    <Grid item xs={12}>
                                        <div className={classes.centerImage}> <Skeleton animation="wave" width={100} height={100} /></div>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} >
                                                <Skeleton animation="wave"  height={120} />
                                                <Skeleton animation="wave"  height={120} />
                                                <Skeleton animation="wave"  height={120} />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Skeleton animation="wave"  height={120} />
                                                <Skeleton animation="wave"  height={120} />
                                                <Skeleton animation="wave"  height={120} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <div className={classes.centerImage}><img src={logo} width="100px"></img></div>
                                        <ImageList variant="masonry" cols={2} gap={8}>
                                            {images.map((item) => (
                                                <ImageListItem key={item.id}>
                                                    <img
                                                        src={`${item.src}?w=248&fit=crop&auto=format`}
                                                        srcSet={`${item.src}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Grid>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>



                <Dialog
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {publishChanges ?
                        <Fragment>
                            <DialogTitle id="alert-dialog-title1">{"   Das hat geklappt!  "}</DialogTitle>
                            <DialogContent >
                                {/* <DialogContentText id="alert-dialog-description1" >
                                   Das hat geklappt!
                                </DialogContentText>*/}
                                {/* <img style={{ margin: "auto", display: "block" }} height={100} src={smilie} alt="Danke :)" /> */}
                                <span style={{ fontSize: 70, textAlign: "center", display: "block" }}>&#128522;</span>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDialogClose} component={NavLink} to="/eventlocation" color="primary" autoFocus>
                                    Schließen
                                </Button>
                            </DialogActions>
                        </Fragment>
                        :
                        <Fragment>
                            <DialogTitle id="alert-dialog-title">Details zur Eventlo­ca­tion</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Möchtest du deine Location auf mamfito.de speichern?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.updateEventLocation(this.state)} color="primary" autoFocus>
                                    Ja
                                </Button>
                                <Button onClick={this.handleDialogClose} color="secondary">
                                    Nein
                                </Button>
                            </DialogActions>
                        </Fragment>}
                </Dialog>

            </div >


        );


    }
}

export default withStyles(useStyles)(withAuth0(withRouter(EventlocationProfileContent)))